import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

type DomainHeaderProps = {
    // Define any additional props here
    title: string;
    domain: string
  };

  
export const DomainHeader: React.FC<PropsWithChildren<DomainHeaderProps>> = ({ title, domain, children }) => {
    const [t] = useTranslation();

    return <div className="header-container">
        <div>
            <h1 className="header-title">
                {title}
            </h1>
            <small className="header-domain">
                {t("Domain")}: {domain}
            </small>
        </div>
        {children && (<div>{children}</div>)}
    </div>
}