import { Axios, AxiosResponse } from "axios";
import { Auth } from 'aws-amplify';
import awsResources from "../aws-resources.json";
import axios from 'axios'

export type API = Axios;
export type PublicAPI = Axios;

const responseParser = (response: AxiosResponse<any, any>) => {
  try {
    if (typeof response.data === 'string') {
      response.data = JSON.parse(response.data);
    }
  } catch {
    /* empty */
  }

  return response;
}

export function createPublicClient(): PublicAPI {
  const publicBaseURL = new URL(awsResources.PublicRestApiEndpoint);
  // For some reason, axios default content-type is application/x-www-form-urlencoded
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  const client = axios.create({
    baseURL: publicBaseURL.toString(),
    headers: defaultHeaders,
  });

  client.interceptors.response.use(
    responseParser,
    function (error) {
      return Promise.reject(error);
    },
  );

  return client;
}

export function createClient(): API {
  const baseURL = new URL(awsResources.RestApiEndpoint);

  // For some reason, axios default content-type is application/x-www-form-urlencoded
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  const client = axios.create({
    baseURL: baseURL.toString(),
    headers: defaultHeaders,
  });


  client.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession();
    if (session == null) {
      throw new Error("We try to attach http authorization headers but we're not logged in.");
    }

    let idToken = session.getIdToken().getJwtToken();

    config.headers['Authorization'] = `Bearer ${idToken}`

    return config;
  });

  client.interceptors.response.use(
    responseParser,
    function (error) {
      return Promise.reject(error);
    },
  );

  return client;
}