import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function ConfirmModal({
    showModal, 
    setShowModal, 
    title, 
    buttonText, 
    activeButtonText, 
    action,
    item,
    text
} : {
    showModal: boolean, 
    setShowModal: (value: boolean) => void, 
    title: string, 
    buttonText: string, 
    activeButtonText: string,
    action: (item: any) => Promise<void>,
    item?: any,
    text?: string
}) {

    const [t] = useTranslation();

    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [actionRunning, setActionRunning] = useState<boolean>(false); 

    const reinitialiseModal = (() => {
        setShowModal(false); 
        setIsError(false);
        setErrorMessage("");
    })

    return <>
    <Modal dialogClassName="modal-lg" show={showModal} onHide={() => reinitialiseModal()} backdrop="static">
    <Modal.Header closeButton>
        <Modal.Title>{t(title)}</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ whiteSpace: 'pre-wrap' }}>
        <div style={{ margin: "10px 10px 30px 10px" }}>{text}</div>
        <Button onClick={(e) => {
                e.preventDefault(); 
                setActionRunning(true);
                action(item)
                .then(() => {
                    setActionRunning(false);
                    reinitialiseModal();
                })
                .catch( error => {
                    console.error('Error:', error);
                    setIsError(true);
                    setErrorMessage(error.message);
                    setActionRunning(false);
                })
            }} 
            variant="danger" 
            type="button"
            disabled={isError}>
            {actionRunning ?  
            (<> {t(activeButtonText)}... <Spinner as="span" animation="border" size="sm" role="status"></Spinner> </>) : 
            (<>{t(buttonText)}</>)}
        </Button>
        <Button onClick={(e) => {
            e.preventDefault(); 
            reinitialiseModal()}}
            variant="secondary" 
            type="button" 
            style={{ marginLeft: "10px" }}>
            {t('Cancel')}
        </Button>
        {isError && <div className="text-danger">{t(errorMessage)}</div>}
    </Modal.Body>
    </Modal> 
    </>
}