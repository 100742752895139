import { injectable, inject } from "inversify";
import { Auth, Hub } from "aws-amplify";
import { Domain } from "../models/Domain";
import awsConfig from "../aws-config.json";
import { DomainRepository } from "./DomainRepository";
import { Credentials } from "./Credentials";
import {
    GetObjectCommand,
    S3,
    _Object,
    CommonPrefix
} from "@aws-sdk/client-s3";  
import { useInjection } from "inversify-react";


@injectable()
export class AWSClientProvider {

    @inject(Credentials)
    public credentials!: Credentials;

    private s3Clients: Map<string, S3> = new Map();

    // Load the session
    // Subscribe to auth (when the token is refreshed we need to update the session)
    constructor() {
        Hub.listen('auth', data => {
            switch (data.payload.event) {
                case 'tokenRefresh':
                    this.s3Clients.clear();
                    break;   
            }
        });
    }

    public async getS3Client(domain: Domain | undefined) {
        if(!domain) {
            throw new Error("We try to set the S3 client but do not have a domain.");
        }

        if(this.s3Clients.has(domain.Id)) {
            return this.s3Clients.get(domain.Id)!
        } else {
            const s3Client = new S3({
                region: awsConfig.aws_cognito_region,
                credentials: await this.credentials.getProvider(domain?.role)
            });
            this.s3Clients.set(domain.Id, s3Client);
            return s3Client;
        }
    }
}