import { useInjection } from "inversify-react";
import { useCallback, useEffect, useState } from "react";
import { API } from "../services/HTTP";
import { useParams } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap"
import DatePicker from 'react-datepicker';
import { Device } from "../models/Device";
import { Settings } from "../models/Settings";
import { Attribute } from "../models/Attribute";
import { useTranslation } from "react-i18next";
import { ExportData } from "../services/ExportData";
import { GenericTimestreamResponse } from "../models/TimestreamResponse";
import _ from "lodash";
import { FetchTokenizedTimestreamData } from "../services/FetchTokenizedTimestreamData";
import { DomainHeader } from "../components/DomainHeader";

export function DataPage() {

    const [t] = useTranslation();

    const api = useInjection<API>("API");
    const exportData = useInjection<ExportData>(ExportData);
    const fetchTimestreamData = useInjection<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData);

    const { domain_id } = useParams();

    const [attributeId, setAttributeId] = useState<string>();
    const [attributeData, setAttributeData] = useState<GenericTimestreamResponse>();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [HTTPErrorMessage, setHTTPErrorMessage] = useState<any>(null);
    const [devices, setDevices] = useState<Device[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
    const [settings, setSettings] = useState<Settings>();
    const [exportedData, setExportedData] = useState<any>();
    const [partialDownload, setPartialDownload] = useState<boolean>(false);
    const [attributeList, setAttributeList] = useState<Attribute[]>([]);


    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;

            const response = await api.get<Device[]>(`domains/${domain_id}/devices`);
            setDevices(response.data);

            const sResponse = await api.get<Settings>(`domains/${domain_id}/settings`);
            setSettings(sResponse.data)

        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    useEffect(() => {
        reload();
    }, [reload]);

    const getAttributeData = useCallback(async () => {
        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;

            const start_timestamp = Math.floor(startDate.getTime() / 1000); // get unix timestamp in seconds
            const end_timestamp = Math.floor(endDate.getTime() / 1000);

            const url = `devices/${selectedDeviceId}/data?start=${start_timestamp}&end=${end_timestamp}`

            const attributeResponse = await fetchTimestreamData.fetchTokenizedData(url);
            setAttributeData(attributeResponse);

        };
        return f().catch(err => console.error(err)).finally(() => setLoading(false));

    }, [domain_id, startDate, endDate, selectedDeviceId, attributeId]);


    return <> 
        <DomainHeader title={t("Download Data")} domain={domain_id ?? ""}/>
        
        <Form style={{padding: "10px"}} onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            getAttributeData()
                .catch(err => {
                    setHTTPErrorMessage(err.response.status);
                })
            exportData.downloadAttributeData(selectedDeviceId!, Math.floor(startDate.getTime() / 1000), Math.floor(endDate.getTime() / 1000), partialDownload ? attributeList : null)
                .catch(error => console.error('Download error:', error))
                .finally(() => setLoading(false));
        }}>
            <Form.Group className="mb-3">
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '10px' }}>
                <strong>{t("Select a device")}*</strong>
                </Form.Label>
                {loading ? (<>
                    <Form.Select id="device" disabled style={{ maxWidth: '300px'}}></Form.Select>
                </>) : (<>
                    <Form.Select
                    id="device"
                    required
                    onChange={(e) => {
                        e.preventDefault();
                        setSelectedDeviceId(e.target.value);
                    }}
                    style={{
                        maxWidth: '300px'
                    }}
                    >
                    <option disabled></option>
                    {devices.map((device) => (
                        <option key={device.Id} value={device.Id}>{device.Id}</option>
                    ))}
                    </Form.Select>
                </>)}
            </div>
            </Form.Group>

            <Form.Group className="mb-3" style={{ marginTop: "30px"}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '10px' }}>
                <strong>{t("Download the data of all the attributes")}?</strong>
                </Form.Label>
                <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                <Form.Check
                    inline
                    type="radio"
                    id="radio1"
                    label={t("Yes")}
                    name="radioGroup"
                    checked={!partialDownload}
                    onChange={() => {
                    setPartialDownload(!partialDownload);
                    }}
                />
                <Form.Check
                    inline
                    type="radio"
                    id="radio2"
                    label={t("No")}
                    name="radioGroup"
                    checked={partialDownload}
                    onChange={() => {
                    setPartialDownload(!partialDownload);
                    }}
                />
                </div>
            </div>
            </Form.Group>

            <div>
                {partialDownload && (<>
                <Form.Group className="mb-3">
                    <Form.Label><strong>{t("Select the relevant attributes")}:</strong></Form.Label>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' }}>
                    {settings?.attributes!.map(attribute => (
                        <Form.Check type="checkbox" id="radio1" label={attribute.incoming_key} name="radioGroup" 
                        checked={attributeList.includes(attribute)}
                        onChange={() => {
                            attributeList.includes(attribute) ?
                            setAttributeList(attributeList.filter(item => item !== attribute)) :
                            setAttributeList([...attributeList, attribute])
                        }}/>
                    ))}
                    </div>
                </Form.Group>
                </>) || (<></>)}
            </div>

            <Form.Group className="mb-3" style={{ marginTop: "30px"}}>
            <div style={{display: 'flex', gap: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '5px' }}><strong>{t("Start Time")}</strong></Form.Label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date!)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '5px' }}><strong>{t("End Time")}</strong></Form.Label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date!)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                </div>
            </div>
            </Form.Group>


            <div className="mb-4" style={{ marginTop: "auto" }}>
            <Button
                disabled={loading || !selectedDeviceId} 
                style={{ marginTop: "10px" }}
                variant="primary"
                type="submit">
                { !loading && (<>{t("Download")}</>) || (<><Spinner animation="border" size="sm" /> {t("Fetching the attribute data")}... </>)}
            </Button>
            </div>
        </Form>
    </>
}