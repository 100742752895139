import { injectable, inject } from "inversify";
import { CofemoTimestreamResponse, GenericTimestreamResponse } from "../models/TimestreamResponse";
import { toExcelDate } from "./Utils";
import * as HTTP from "./HTTP";
import { AWSClientProvider } from "./AWSClientProvider";
import { Domain } from "../models/Domain";
import { File } from "../models/File";
import { GetObjectCommand,
    _Object
 } from "@aws-sdk/client-s3";
import JSZip from 'jszip';
import { Event } from "../models/Event"
import { Patient } from "../models/Patient";
import { json2csv } from 'json-2-csv';
import { Attribute } from "../models/Attribute";
import { FetchTokenizedTimestreamData } from "./FetchTokenizedTimestreamData";

@injectable()
export class ExportData {

    @inject("API")
    public api!: HTTP.API;
    
    @inject(AWSClientProvider)
    public s3ClientProvider!: AWSClientProvider;

    @inject(FetchTokenizedTimestreamData)
    public fetchTimestreamData!: FetchTokenizedTimestreamData;

    // Convert a timestream response to a csv format (as a string)
    private toCsvData = (data: GenericTimestreamResponse) => {
        const headers = Object.keys(data);

        const maxLength = Math.max(...headers.map(key => data[key].length));

        const headerRow = headers.join(",");
        const iso8601Format = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

        // Create the data rows
        const dataRows = Array.from({ length: maxLength }, (_, i) => {
            return headers.map(key => {
                if (data[key][i]) {
                    // Check if the row matches the ISO 8601 date format
                    if (iso8601Format.test(data[key][i])) {
                        // Convert to a Date object and return
                        return toExcelDate(new Date(data[key][i]));
                    } else {
                        return data[key][i];
                    }
                } else {
                    return "";
                }
            }).join(",");
        });
        
        const csvData = [headerRow, ...dataRows].join("\n");

        // BOM support for special characters in Excel
        const byteOrderMark = "\ufeff";

        // Convert CSV data to file-like object of immutable, raw data for zipping
        const blob = new Blob([byteOrderMark, csvData], { type: 'text/csv;charset=utf-8;' });

        return blob;
    } 

    // Get the data from Timestream since the creation of the patient and return a blob for zipping
    private async getTimestreamDataCSV(domain_id: string, patient_id: string) {

        const response = await this.api.get<Patient>(`domains/${domain_id}/patients/${patient_id}`)
        const patient = response.data;

        const startTimestamp = Math.floor(new Date(patient.created_at).getTime() / 1000)
        const endTimestamp = Math.floor(new Date().getTime() / 1000)

        const url = `domains/${domain_id}/patients/${patient_id}/data?start=${startTimestamp}&end=${endTimestamp}`;
        const allData = await this.fetchTimestreamData.fetchTokenizedCofemoData(url, false)
        // Convert received data to CSV format
        return this.toCsvData(allData);
    }

    private async getCsvEvents(domain_id: string, patient_id: string) {
        const eventsResponse = await this.api.get<Event[]>(`domains/${domain_id}/patients/${patient_id}/events`);
        const events = eventsResponse.data;

        return json2csv(events.map(event => ({
            "Domain": event.domain_id,
            "Patient": event.patient_id,
            "Event date": toExcelDate(new Date(event.created_at!)),
            "Event title": event.title,
            "Text": event.text,
        })))
    }

    private async initiateDownload(blob: Blob, fileName: string) {
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
    
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    private async getS3FilesByPrefix(domain: Domain, bucket: string, prefix: string) {

        const s3Client = await this.s3ClientProvider.getS3Client(domain);

        let allFiles: _Object[] = [];

        try {
            const response = await s3Client!.listObjectsV2({ Bucket: bucket, Prefix: prefix });

            if (response) {
                allFiles.push(...response.Contents!.filter(obj => !obj.Key?.endsWith('/')));
            } else {
                console.error("Empty response from listObjectsV2");
                console.error(domain);
            }
        } catch (error) {
            console.error("Error fetching objects:", error);
        }
        return allFiles;
    }

    public async downloadPerPrefix(domain: Domain, bucket: string, prefix: string, progress: (percentage: number) => void = (_) => {}) {

        const fileList = await this.getS3FilesByPrefix(domain, bucket, prefix);

        const todo = fileList.length + 2; // Files plus 
        var done = 0;

        const zip = new JSZip();

        for (const file of fileList) {
            const s3ReadableStream = await this.getReadableStreamFromS3(file.Key!, domain, bucket);

            // slice to remove the prefix from the filenames
            // use join('/') if you want to keep the file structure within the zipped file
            let filename = file.Key!.split('/').slice(1).join('_')
            if(filename.endsWith('.dat'))
                filename = filename.slice(0, filename.length - 4) + '.csem_dat'
    
            const blob = new Blob([s3ReadableStream!], { type: 'application/octet-stream' });
    
            zip.file(filename, blob, { binary: true, date: file.LastModified });

            done += 1;
            progress(done / todo);
        }

        const blob = await zip.generateAsync({ type: 'blob' });

        this.initiateDownload(blob, prefix)
    }

    // Fetches the specified S3 object and returns its content as a readable stream.
    private async getReadableStreamFromS3(key: string, domain: Domain, bucket: string) {
        const s3Client = await this.s3ClientProvider.getS3Client(domain);

        const command = new GetObjectCommand({
            Bucket: bucket,
            Key: key,
        })

        const response = await s3Client?.send(command);
        return response?.Body?.transformToByteArray();
    }

    // Zips a list of S3 files and Timestream CSV 
    // Initiates a download of the zip
    private async zipCofemoFiles( fileList: File[], timestreamCSVFile: Blob, CsvEvents: string, domain: Domain, patient_id: string, progress: (percentage: number) => void = (_) => {}) {

        const todo = fileList.length + 2; // Files plus 
        var done = 0;
        const zip = new JSZip();

        for (const file of fileList) {
            const s3ReadableStream = await this.getReadableStreamFromS3(file.key!, domain, domain.bucket);
            const fileName = file.key!.split('/').join('_');
    
            const blob = new Blob([s3ReadableStream!], { type: 'application/octet-stream' });
    
            zip.file(fileName, blob, { binary: true });
            done += 1;
            progress(done / todo);
        }

        zip.file(`AllTemperatureData.csv`, timestreamCSVFile);
        done += 1;

        zip.file(`AllEvents.csv`, CsvEvents);
        done += 1;

        progress(done / todo);

        const blob = await zip.generateAsync({ type: 'blob' });

        this.initiateDownload(blob, `AllCofemoData_${patient_id}`)
    }

    // A public function to initiate the download process (fetches S3 and Timestream data and creates a downloadable zip file)
    public async downloadCofemoData(domain_id: string, patient_id: string, progress: (percentage: number) => void = (_) => {}) {
        const domain = await this.api.get<Domain>(`domains/${domain_id}`);

        const timestreamCSVFile = await this.getTimestreamDataCSV(domain_id, patient_id);

        const response = await this.api.get(`domains/${domain_id}/patients/${patient_id}/files`);
        const s3FileList = response.data.files;

        const CsvEvents = await this.getCsvEvents(domain_id, patient_id);

        await this.zipCofemoFiles(s3FileList, timestreamCSVFile, CsvEvents, domain.data, patient_id, progress)
    }

    public async downloadAttributeData(device_id: string, start_timestamp: number, end_timestamp: number, attribute_list?: Attribute[] | null) {
        
        const url = `devices/${device_id}/data?start=${start_timestamp}&end=${end_timestamp}`
        const timestreamData = await this.fetchTimestreamData.fetchTokenizedData(url)
        
        const startDateString = new Date(start_timestamp * 1000).toISOString().slice(0,10).replace(/-/g,'');
        const endDateString = new Date(end_timestamp * 1000).toISOString().slice(0,10).replace(/-/g,'')

        // if the user chose to check only certain attributes
        if(attribute_list) {
            var partialAttributeData: GenericTimestreamResponse = {};
            
            attribute_list.forEach(attribute => {
            // Check if the key exists in the timestream data
            if (timestreamData.hasOwnProperty(attribute.storage_key)) {
                partialAttributeData[attribute.storage_key] = timestreamData[attribute.storage_key];
            }
            });

            partialAttributeData["timestamps"] = timestreamData["timestamps"]; 
            this.initiateDownload(this.toCsvData(partialAttributeData), `${device_id}_${startDateString}-${endDateString}`)
        } else {
            this.initiateDownload(this.toCsvData(timestreamData), `${device_id}_${startDateString}-${endDateString}`)
        }
    }
    
    public async downloadHygieSensorData(device_id: string, start_timestamp: number, end_timestamp: number, sensor_list?: string[] | null) {
        if(sensor_list) {
            sensor_list.map(async sensor => {
                const url = `hygiedevices/${device_id}/data/${sensor}?start=${start_timestamp}&end=${end_timestamp}`
                let response = await this.api.get(url);
                while(response.data.nextToken) {
                    response = await this.api.get(`${url}&next_token=${response.data.nextToken}`)
                }
            })
        } else {
            const url = `hygiedevices/${device_id}/data?start=${start_timestamp}&end=${end_timestamp}`
            let response = await this.api.get(url);
            while(response.data.nextToken) {
                response = await this.api.get(`${url}&next_token=${response.data.nextToken}`)
            }
        }
    }
}