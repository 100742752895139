import { useState, useCallback, useEffect } from "react";
import { useInjection } from "inversify-react";
import { useParams } from "react-router-dom";
import { API } from "../services/HTTP";
import { Event } from "../models/Event"
import { useTranslation } from "react-i18next";
import { SortableTable } from "../components/SortableTable";
import { Spinner } from "react-bootstrap";
import { DomainHeader } from "../components/DomainHeader";

export function EventsPage() {

    const [t, i18n] = useTranslation();
    const api = useInjection<API>("API");
    let { domain_id } = useParams();
    const [loading, setLoading] = useState(false);
    const [sortBy, setSortBy] = useState<string>("created_at");
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const [events, setEvents] = useState<Event[]>([]);

    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;

            const events = await api.get<Event[]>(`domains/${domain_id}/events`);
            setEvents(events.data);
        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    useEffect(() => {
        reload();
    }, [domain_id]);

    const columns = [
        {name: "Trigger time", key: "created_at", format: (item: string) => new Date(item).toLocaleString(t('dateFormat'))},
        {name: "Patient ID", key: "patient_id"},
        {name: "Alert Type", key: "title"},
        {name: "Alert", key: "text"},
    ];

    return <>
        <DomainHeader title={t("Patient Events")} domain={domain_id ?? ""}/>
        
        <SortableTable 
                columns={columns}
                data={events}
                loading={loading}
                setSort={setSortBy}
                setOrder={setSortOrder}
            />
    </>
}