import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInjection } from "inversify-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { API } from "../services/HTTP";
import { DomainUser } from "../models/DomainUser";
import { SortableTable } from "../components/SortableTable";
import { DomainHeader } from "../components/DomainHeader";
import { SpinningButton } from "../components/SpinningButton";
import ErrorComponent from "../components/ErrorComponent";

export interface UserManagementPageProps {
    isInDomain?: boolean;
}

export const UserManagementPage: React.FC<UserManagementPageProps> = ({ isInDomain = true }) => {
    const { t } = useTranslation();
    const api = useInjection<API>("API");
    const { domain_id } = useParams();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<DomainUser[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [addUserModelOpen, setAddUserModalOpen] = useState(false);

    const fetchUserList = useCallback(async (isInDomain: boolean) => {
        try {
            setLoading(true);
            setError(null);
            const url = `user-management/domains/${domain_id}/users?in_domain=${isInDomain}`
            const response = await api.get<DomainUser[]>(url);
            setUsers(response.data);
        } catch (error) {
            setError(`${error}`);
        } finally {
            setLoading(false);
        }
    }, [api, isInDomain, domain_id]);

    useEffect(() => {
        fetchUserList(isInDomain);
    }, [fetchUserList, isInDomain, domain_id]);

    const handleModalClose = useCallback(() => {
        setAddUserModalOpen(false);
        fetchUserList(true);
    }, [fetchUserList]);

    const handleActionClick = useCallback(async (item: DomainUser) => {
        try {
            setError(null);
            const method = isInDomain ? 'delete' : 'put';
            const url = `user-management/domains/${domain_id}/users`;
            const data = isInDomain ? { data: { userName: item.userName } } : { userName: item.userName };
            await api[method]<DomainUser[]>(url, data);
            setUsers(prevUsers => prevUsers.filter(user => user.userName !== item.userName));
        } catch (error) {
            setError(`${error}`);
        }
    }, [api, isInDomain, domain_id]);

    if (error)
        return <ErrorComponent error={error} />

    return (
        <>
            <DomainHeader title={!isInDomain ? t("Add User") : t("User Management")} domain={domain_id ?? ""}>
                {isInDomain && (
                        <Button
                            variant="outline-secondary"
                            onClick={() => setAddUserModalOpen(true)}>
                            + {t('Add User')}
                        </Button>
                )}
            </DomainHeader>

            <SortableTable
                columns={[
                    { name: "User Name", key: "name" },
                    { name: "User Email", key: "email" },
                    { name: t("Action"), key: "action", 
                        aggregate: (item) => <SpinningButton onClick={(e) => handleActionClick(item) }>{isInDomain ? t("–") : t("+")}</SpinningButton>
                    }
                ]}
                data={users}
                loading={loading}
                setOrder={() => { }}
                setSort={() => { }}
            />
            <Modal
                show={addUserModelOpen}
                onHide={handleModalClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('All Users')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserManagementPage isInDomain={false} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UserManagementPage;
