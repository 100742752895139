import { Auth } from "aws-amplify";
import { Button, Card, Col, Container, Form, Modal, Nav, Navbar, NavLink, NavDropdown, Dropdown, Row, Spinner, Tooltip } from "react-bootstrap"
import {
    Link,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import logo from "../assets/CSEM_logo1.png";
import { Domain } from "../models/Domain";
import { useCallback, useEffect, useState } from "react";
import { useInjection } from "inversify-react";
import { UserService } from "../services/User";
import { LoginRedirectStorage } from "../services/LoginRedirectStorage";
import { DomainRepository } from "../services/DomainRepository";
import { isReturnStatement } from "typescript";
import { DefaultDomain } from "../services/DefaultDomain";
import { useTranslation } from "react-i18next";
import versions from "../versions.json";

export function Frame({
    userAttributes,
    onLogout,
    onRefreshUserClicked,
    onCreateDomain
}: {
    userAttributes: Map<string, string> | null,
    onLogout: () => void,
    onRefreshUserClicked: () => Promise<void>,
    onCreateDomain: () => void,
}) {
    const location = useLocation();
    const user = useInjection<UserService>(UserService);
    const defaultDomain = useInjection<DefaultDomain>(DefaultDomain);
    const domainRepository = useInjection<DomainRepository>(DomainRepository);
    let { domain_id } = useParams();

    const [refreshLoading, setRefreshLoading] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    const [currentDomain, setCurrentDomain] = useState<Domain | null>();
    const [domains, setDomains] = useState<Map<string, Domain> | null>();
    const [t, i18n] = useTranslation();
    const [showLanguagePicker, setShowLanguagePicker] = useState(false);
    const [isHygie, setIsHygie] = useState<boolean>(false);

    const refreshUser = useCallback((e: any) => {
        e.preventDefault();
        setRefreshLoading(true);
        onRefreshUserClicked()
            .then(() => domainRepository.all(true))
            .then(setDomains)
            .finally(() => setRefreshLoading(false));

    }, [onRefreshUserClicked]);

    useEffect(() => {
        user.isAdmin().then(admin => setIsAdmin(admin));
        domainRepository.all().then(setDomains);

    }, [user]);

    useEffect(() => {
        if(!domain_id) {
            return;
        }

        domainRepository.get(domain_id)
            .then(setCurrentDomain)
            .then(() => defaultDomain.set(domain_id));

        // Non-ideal workaround: The hygiedata page is currently specific to the Hygie domain.
        // As of 27/08/24, the API is tailored to Hygie and requires refactoring for broader domain usability
        setIsHygie(domain_id.toLowerCase().includes("hygie"))
        
    }, [domain_id])

    return <>
        <Container fluid>
            <Row className="main-content-row">
                <Col xxl={3} xl={4} id="frame-container">
                    <Navbar expand="lg" className="d-flex flex-column h-100">
                        <Nav className="sidebar flex-column flex-grow-1" activeKey={location.pathname}>
                            <Nav.Item>
                                <Row>
                                    <Col>
                                    <Row>
                                        <img style={{ width: "80%", maxWidth: "300px", margin: "15px" }} src={logo} alt="CSEM Logo" />
                                    </Row>
                                    </Col>
                                    <div className="col-auto">
                                        <Navbar.Toggle />
                                    </div>
                                </Row>
                                
                            </Nav.Item>
                            <Nav.Item>
                                <Navbar.Text>
                                    {userAttributes?.get("name")} &nbsp;
                                    
                                    <Button variant='outline-primary' className="btn-sm" onClick={refreshUser}>
                                        {(refreshLoading && (
                                            <Spinner size="sm"></Spinner>
                                        )) || (
                                                <i className="bi bi-arrow-clockwise"></i>
                                            )}

                                    </Button>
                                    &nbsp;
                                    <Button onClick={async () => {
                                        onLogout();
                                    }} variant='outline-primary' className="btn-sm">
                                        <i className="bi bi-box-arrow-right"></i>
                                    </Button>
                                    &nbsp;

                                    <Button onClick={() => setShowLanguagePicker(!showLanguagePicker)}
                                    variant='outline-primary' className="btn-sm">
                                        <i className="bi bi-translate"></i>
                                    </Button>
                                    <Modal show={showLanguagePicker} onHide={() => setShowLanguagePicker(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t("Choose language")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <Form.Select size="sm" id="languageInput" value={i18n.language}onChange={(e) => {
                                            i18n.changeLanguage(e.target.value)
                                            localStorage.setItem("DEFAULT_LANGUAGE", e.target.value);
                                            setShowLanguagePicker(false);
                                            }}>
                                        {Object.keys(i18n.store.data).map(lng => <option value={lng} key={lng}>{t(lng)}</option>)}
                                    </Form.Select>
                                        </Modal.Body>
                                    </Modal>

                                </Navbar.Text>
                            </Nav.Item>                         
                            <Navbar.Collapse>
                                <hr />
                                <Nav.Item>
                                    {/* <Navbar.Text> */}
                                            <div className="col">
                                            <div className="input-group mb-3">
                                                <label className="input-group-text">{t("Domain")}</label>
                                                {domains && (
                                                    <select id="domainInput" className="form-select" value={currentDomain?.Id} onChange={(e) => navigate(`/d/${e.target.value}`)}>
                                                        {Array.from(domains!.entries()).map(([domainId, domain]) => {
                                                            return <option value={domainId} key={domainId}>{domain.name}</option>;
                                                        })}
                                                    </select>
                                                ) || (
                                                    <select id="domainInput" className="form-select" disabled>
                                                        <option value="">Loading...</option>
                                                    </select>
                                                )}
                                                
                                                {isAdmin && (
                                                <button onClick={(e) => {e.preventDefault(); onCreateDomain()}} className="btn btn-outline-primary" type="button"><i className="bi bi-plus"></i></button>
                                                )}
                                                </div>
                                            </div>
                                    {/* </Navbar.Text> */}
                                </Nav.Item>
                                
                                <Nav.Item style={{ display: 'flex', alignItems: 'center' }}>
                                    <Nav.Link as={Link} to="files" href={`/d/${domain_id}/files`} className={location.pathname.includes("/files") ? "active" : ""}>
                                        {t("Files")}
                                    </Nav.Link>
                                    {currentDomain?.settings.parsingEnabled && (<>
                                        <span>/</span>
                                        <Nav.Link as={Link} to="processed-files" href={`/d/${domain_id}/processed-files`} className={location.pathname.includes("processed-files") ? "active" : ""}>
                                            {t("Parsed Files")}
                                        </Nav.Link>
                                    </>)}
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link as={Link} to="devices" href={`/d/${domain_id}/devices`} className={location.pathname.includes("devices") ? "active" : ""}>
                                        {t("Wearables")}
                                    </Nav.Link>
                                </Nav.Item>
                                { currentDomain && currentDomain.settings.isCofemoDomain ?
                                    ( <> 
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="patients" href={`/d/${domain_id}/patients`} className={location.pathname.includes("patients") && !location.pathname.includes("patients/archived") && !location.pathname.includes("patients/events") ? "active" : ""}>
                                            {t("Patients")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="patients/archived" href={`/d/${domain_id}/patients/archived`} className={location.pathname === `/d/${domain_id}/patients/archived` ? "bold" : ""}>
                                            {t("View archived patients")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link as={Link} to="patients/events" href={`/d/${domain_id}/patients/events`} className={location.pathname === `/d/${domain_id}/patients/events` ? "bold" : ""}>
                                            {t("Events")}
                                    </Nav.Link>
                                    </Nav.Item></>) : 
                                    (<></>)
                                }
                                {currentDomain && currentDomain.settings.isTimestreamRequired ? (<>
                                    <Nav.Item>
                                    <Nav.Link as={Link} to={isHygie ? "hygiedata" : "data"} href={`/d/${domain_id}/${isHygie ? 'hygiedata' : 'data'}`} className={location.pathname === `/d/${domain_id}/${isHygie ? 'hygiedata' : 'data'}` ? "bold" : ""}>
                                            {isHygie ? "Hygie Data" : t("Data")}
                                    </Nav.Link>
                                    </Nav.Item>
                                 </>) : (<> </>)}
                                {
                                    isAdmin ? (
                                        <Nav.Item>
                                                <Nav.Link as={Link} to="users" href={`/d/${domain_id}/users`} className={location.pathname === `/d/${domain_id}/users` ? "bold" : ""}>
                                                        {t("User Management")}
                                                </Nav.Link>
                                        </Nav.Item>
                                    ) : (<></>)
                                }
                            </Navbar.Collapse>

                            <hr />
                            <Nav.Item className="mt-auto mb-4 d-flex justify-content-end w-100">
                                {isAdmin && (
                                    <Button
                                        onClick={(e) => { e.preventDefault(); navigate(`/d/${domain_id}/settings`) }}
                                        href={`/d/${domain_id}/settings`}
                                        variant='outline-primary'
                                        className="btn-sm"
                                    >
                                        <i className="bi bi-gear"></i>
                                    </Button>
                                )}
                            </Nav.Item>

                            <Nav.Item className="my-auto text-center w-100 text-watermark">
                                {versions["git-version"]}
                            </Nav.Item>
                        </Nav>
                    </Navbar>
                </Col>
                <Col xxl={9} xl={8} id="page-content-wrapper">
                    <Outlet />
                </Col>
            </Row>
        </Container>
    </>

}