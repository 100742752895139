import { useCallback, useEffect, useRef, useState } from "react";
import { Domain } from "../models/Domain";
import { Patient, CreatePatient } from "../models/Patient";
import { useInjection } from "inversify-react";
import { Button, Spinner, Modal, Dropdown } from "react-bootstrap";
import { API } from "../services/HTTP";
import { UserService } from "../services/User";
import { PatientTile } from "../components/PatientTile";
import { useParams } from "react-router-dom";
import { DomainRepository } from "../services/DomainRepository";
import { NewPatientForm } from "../components/NewPatientForm";
import { useTranslation } from "react-i18next";
import { DomainHeader } from "../components/DomainHeader";

export function PatientsPage() {
    const api = useInjection<API>("API");
    const user = useInjection<UserService>(UserService);

    const [t, i18n] = useTranslation();

    const [patients, setPatients] = useState<Patient[]>([]);

    const [loading, setLoading] = useState(false);
    const domainRepository = useInjection<DomainRepository>(DomainRepository);

    let { domain_id } = useParams();
    const [domain, setDomain] = useState<Domain | null>();

    const [isAdmin, setIsAdmin] = useState(false);
    const [showCreatePatientModal, setShowCreatePatientModal] = useState(false);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [sortBy, setSortBy] = useState<keyof Patient>('created_at');

    const handleSort = useCallback((patients: Patient[]) => {
        return patients.sort((a,b) => {
            const aValue = a[sortBy] ?? false;
            const bValue = b[sortBy] ?? false;
            if (sortOrder === "asc") {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
        })
    }, [sortBy, sortOrder])

    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;
            const response = await api.get<Patient[]>(`domains/${domain_id}/patients`);
            
            setPatients(handleSort(response.data));

            setDomain(await domainRepository.get(domain_id));
        };
        f().finally(() => setLoading(false));

    }, [domain_id, api, handleSort])

    useEffect(() => {
        reload();
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [reload]);

    useEffect(() => {
        const interval = setInterval(() => {
          reload();
        }, 60000);
      
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [reload])

    const onCreatePatient = (() => setShowCreatePatientModal(true))

    const createPatient = useCallback(async (patient: CreatePatient) => {
        await api.post(`domains/${domain_id}/patients`, patient);
        reload();
    
        setShowCreatePatientModal(false);
    
    }, [api]);

    return <>
        <DomainHeader title={t("Patients")} domain={domain_id ?? ""}>
                <Button 
                onClick={(e) => {e.preventDefault(); onCreatePatient()}} 
                type="button" 
                variant="outline-secondary"
                style={{marginRight: '10px'}}>
                    + {t('Create Patient')}
                </Button>
                <Button disabled={loading} onClick={(e) => {e.preventDefault(); reload()}} variant="outline-secondary">
                    {loading && (
                        <Spinner 
                        as="span"
                        animation="border"
                        size="sm"
                        role="status" />
                    ) || (
                        <i className="bi bi-arrow-clockwise"></i>
                    )}
                </Button>
        </DomainHeader>

        <div className="mb-4 d-flex justify-content-end" style={{marginRight: "10px"}}>
            <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic" > <i className="bi bi-arrow-down-up"></i> {t("Sort By")} </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item className={sortBy === "created_at" && sortOrder === "desc" ? "active" : ""} onClick={(e) => {e.preventDefault(); setSortBy("created_at"); setSortOrder("desc");}}>{t("Created (Newest to Oldest)")}</Dropdown.Item>
                    <Dropdown.Item className={sortBy === "created_at" && sortOrder === "asc" ? "active" : ""} onClick={(e) => {e.preventDefault(); setSortBy("created_at"); setSortOrder("asc");}}>{t("Created (Oldest to Newest)")}</Dropdown.Item>
                    <Dropdown.Item className={sortBy === "id" && sortOrder === "asc" ? "active" : ""} onClick={(e) => {e.preventDefault(); setSortBy("id"); setSortOrder("asc");}}>Patient ID (A-Z)</Dropdown.Item>
                    <Dropdown.Item className={sortBy === "id" && sortOrder === "desc" ? "active" : ""} onClick={(e) => {e.preventDefault(); setSortBy("id"); setSortOrder("desc");}}>Patient ID (Z-A)</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>

        <div className="row">
            {loading && patients.length == 0 ? (<>
                    <Spinner animation="border" />
                </>) : (<>
                    {patients.map(pat => pat.data ? 
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12" key={pat.id}>
                            <PatientTile 
                                patient={pat}
                                canDelete={true}
                                blind={false}
                                includeDetails={false}
                                reloadFunc={reload}
                                ></PatientTile>
                        </div>
                        : <></>
                    )
                    }</>)}            
        </div>     

        <Modal show={showCreatePatientModal} onHide={() => setShowCreatePatientModal(false)} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('Create Patient')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NewPatientForm createPatient={createPatient} />
            </Modal.Body>
        </Modal>  
    </>;
}