import { useInjection } from "inversify-react";
import { useCallback, useEffect, useState } from "react";
import { API } from "../services/HTTP";
import { useParams } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap"
import DatePicker from 'react-datepicker';
import { Device } from "../models/Device";
import { Settings } from "../models/Settings";
import { Attribute } from "../models/Attribute";
import { useTranslation } from "react-i18next";
import { ExportData } from "../services/ExportData";
import { GenericTimestreamResponse } from "../models/TimestreamResponse";
import _ from "lodash";
import { FetchTokenizedTimestreamData } from "../services/FetchTokenizedTimestreamData";
import { useNavigate } from "react-router-dom";

export function HygieDataPage() {

    const [t] = useTranslation();

    const api = useInjection<API>("API");
    const exportData = useInjection<ExportData>(ExportData);
    const fetchTimestreamData = useInjection<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData);

    const { domain_id } = useParams();

    const [attributeId, setAttributeId] = useState<string>();
    const [attributeData, setAttributeData] = useState<GenericTimestreamResponse>();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [HTTPErrorMessage, setHTTPErrorMessage] = useState<any>(null);
    const [devices, setDevices] = useState<Device[]>([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
    const [settings, setSettings] = useState<Settings>();
    const [partialDownload, setPartialDownload] = useState<boolean>(false);
    const [attributeList, setAttributeList] = useState<string[]>([]);
    const [sensorsList, setSensorsList] = useState<string[]>([])
    const [message, setMessage] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);
    const [success, setSuccess] = useState(false);

    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;

            const response = await api.get<Device[]>(`domains/${domain_id}/devices`);
            setDevices(response.data);

            const sResponse = await api.get<Settings>(`domains/${domain_id}/settings`);
            setSettings(sResponse.data)

            const sensors = new Set<string>();
            sResponse.data.attributes!.forEach(attribute => {
                if (attribute.storage_key.includes("_")) {
                    const baseName = attribute.storage_key.split("_")[0];
                    sensors.add(baseName);
                }
            });
            setSensorsList(Array.from(sensors))

        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    const initiateDownloadToS3 = useCallback(async () => {
        const start_timestamp = Math.floor(startDate.getTime() / 1000); // get unix timestamp in seconds
        const end_timestamp = Math.floor(endDate.getTime() / 1000);
        if(attributeList.length > 0) {
            attributeList.map(async sensor => {
                const response = await api.get(`hygiedevices/${selectedDeviceId}/data/${sensor}?start=${start_timestamp}&end=${end_timestamp}`)
            })
        } else {
            const response = await api.get(`hygiedevices/${selectedDeviceId}/data?start=${start_timestamp}&end=${end_timestamp}`)
        }
        return
    }, [startDate, endDate, attributeList, api, domain_id])

    useEffect(() => {
        reload();
    }, [reload]);

    useEffect(() => {
        if (showMessage) {
            const timer = setTimeout(() => {
                setShowMessage(false);
                setSuccess(false);
            }, 10000); // 10 seconds

            return () => clearTimeout(timer);
        }
    }, [showMessage]);

    return <> 
        <div className="header-container">
            <div>
            <h1 className="header-title">
                {t("Download Data")}
            </h1>
            <small className="header-domain">
                {t("Domain")}: {domain_id}
            </small>
            </div>
        </div>

        <Form style={{padding: "10px"}} onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
                await initiateDownloadToS3();
                setMessage("Your data is being uploaded to S3, you will be able to access it within a few minutes");
                setSuccess(true)
            } catch (error) {
                console.error("Error triggering the data upload: ", error)
                setMessage("There was an error triggering the data upload");
            } finally {
                setLoading(false)
                setShowMessage(true)
            }
        }}>
            <Form.Group className="mb-3">
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '10px' }}>
                <strong>{t("Select a device")}*</strong>
                </Form.Label>
                {loading ? (<>
                    <Form.Select id="device" disabled style={{ maxWidth: '300px'}}></Form.Select>
                </>) : (<>
                    <Form.Select
                    id="device"
                    required
                    onChange={(e) => {
                        e.preventDefault();
                        setSelectedDeviceId(e.target.value);
                    }}
                    style={{
                        maxWidth: '300px'
                    }}
                    >
                    <option disabled></option>
                    {devices.map((device) => (
                        <option key={device.Id} value={device.Id}>{device.Id}</option>
                    ))}
                    </Form.Select>
                </>)}
            </div>
            </Form.Group>

            <Form.Group className="mb-3" style={{ marginTop: "30px"}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '10px' }}>
                <strong>{t("Download the data of all the sensors")}?</strong>
                </Form.Label>
                <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                <Form.Check
                    inline
                    type="radio"
                    id="radio1"
                    label={t("Yes")}
                    name="radioGroup"
                    checked={!partialDownload}
                    onChange={() => {
                    setPartialDownload(!partialDownload);
                    }}
                />
                <Form.Check
                    inline
                    type="radio"
                    id="radio2"
                    label={t("No")}
                    name="radioGroup"
                    checked={partialDownload}
                    onChange={() => {
                    setPartialDownload(!partialDownload);
                    }}
                />
                </div>
            </div>
            </Form.Group>

            <div>
                {partialDownload && (<>
                <Form.Group className="mb-3">
                    <Form.Label><strong>{t("Select the relevant sensors")}:</strong></Form.Label>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' }}>
                    {sensorsList.map(sensor => (
                        <Form.Check type="checkbox" id="radio1" label={sensor} name="radioGroup" key={sensor}
                        checked={attributeList.includes(sensor)}
                        onChange={() => {
                            attributeList.includes(sensor) ?
                            setAttributeList(attributeList.filter(item => item !== sensor)) :
                            setAttributeList([...attributeList, sensor])
                        }}/>
                    ))}
                    </div>
                </Form.Group>
                </>) || (<></>)}
            </div>

            <Form.Group className="mb-3" style={{ marginTop: "30px"}}>
            <div style={{display: 'flex', gap: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '5px' }}><strong>{t("Start Time")}</strong></Form.Label>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date!)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <Form.Label style={{ marginBottom: '5px' }}><strong>{t("End Time")}</strong></Form.Label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date!)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                </div>
            </div>
            </Form.Group>


            <div className="mb-4" style={{ marginTop: "auto" }}>
            <Button
                disabled={loading || !selectedDeviceId} 
                style={{ marginTop: "10px" }}
                variant="primary"
                type="submit">
                { !loading && (<>{t("Download")}</>) || (<><Spinner animation="border" size="sm" /> {t("Fetching the attribute data")}... </>)}
            </Button>
            {showMessage ? (<>
                <span className={`text-${success ? "success" : "danger"}`} style={{marginRight: "10px"}}> {success ? <i className="bi bi-check-all"></i> : <i className="bi bi-x"></i>} {t(message)}</span>
            </>) : (<></>)}
            </div>
        </Form>
    </>
}