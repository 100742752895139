import "reflect-metadata"
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "reflect-metadata";
import { DependencyInjection } from "./services/DependencyInjection";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
  <React.StrictMode>
    <DependencyInjection>
      <script src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js"></script>
      <App />
    </DependencyInjection>
  </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
