import { useInjection } from "inversify-react";
import { DefaultDomain } from "../services/DefaultDomain";
import { Domain } from "../models/Domain";
import { useCallback, useEffect, useState } from "react";
import background from '../assets/CSEM_background.jpg'
import { Card, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { LoginRedirectStorage } from "../services/LoginRedirectStorage";
import { UserService } from "../services/User";

export function DefaultDomainRedirect({ 
    onRefreshUserClicked, 
    onCreateDomain,
    onLogoutClicked,
}: { 
    onRefreshUserClicked: () => Promise<void>, 
    onCreateDomain: () => void,
    onLogoutClicked: () => void,
}) {
    const defaultDomainStorage: DefaultDomain = useInjection(DefaultDomain);
    const loginRedirectStorage = useInjection(LoginRedirectStorage);
    const user = useInjection<UserService>(UserService);
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    const [defaultDomain, setDefaultDomain] = useState<string | null | undefined>();

    useEffect(() => {
        if(!user)
            return
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [user]);

    const refreshUser = useCallback((e: any) => {
        e.preventDefault();
        setDefaultDomain(undefined);
        onRefreshUserClicked()
            .then(async () => await defaultDomainStorage.get())
            .then(setDefaultDomain);
    }, [onRefreshUserClicked]);

    useEffect(() => {
        // If we have a redirect saved from before login, we go there.
        const redirect = loginRedirectStorage.retrieveAndDeleteRedirect();
        if (redirect) {
            return navigate(redirect);
        }

        // Otherwise we get the default domain to select.
        defaultDomainStorage.get()
            .then(setDefaultDomain);
    }, [defaultDomainStorage]);

    if (defaultDomain === undefined)
        return <div className="bg-img" style={{ backgroundImage: `url(${background})` }}>
            <Card className="login-modal">
                <Spinner color="black"></Spinner>
            </Card>
        </div>

    // If domains are loaded and there are none, we display this.
    if (defaultDomain === null && !isAdmin)
        return <div className="bg-img" style={{ backgroundImage: `url(${background})` }}>
            <Card className="login-modal">
                <h2>You are not part of any domain, please ask an administrator to give you permissions to at least one domain.</h2>
                <a href="#" onClick={(e) => refreshUser(e)}>Reload</a>
                <a href="#" onClick={(e) => onLogoutClicked()}>Logout</a>
            </Card>
        </div>

    if (defaultDomain === null && isAdmin)
        return <div className="bg-img" style={{ backgroundImage: `url(${background})` }}>
            <Card className="login-modal">
                <h2>Create a domain.</h2>
                <a href="#" onClick={async () => onCreateDomain()}>Create Domain</a>
            </Card>
        </div>

    return <Navigate to={`/d/${defaultDomain}`}></Navigate>
}