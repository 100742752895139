import { inject, injectable } from "inversify";
import { Domain } from "../models/Domain";
import * as HTTP from "./HTTP";
import { Patient } from "../models/Patient";

@injectable()
export class PatientsRepository {
    private cache = new Map<string, Map<string, Patient>>();
    private allInitialized = new Map<string, boolean>();

    @inject("API")
    public api!: HTTP.API;

    public async all(domainId: string, invalidateCache?: boolean) {
        if(invalidateCache) {
            this.cache.clear();
            this.allInitialized.delete(domainId);
        }

        if(!this.allInitialized.get(domainId)) {
            const patients = await this.api.get<Patient[]>(`domains/${domainId}/patients`);
            var patientMap = new Map<string, Patient>();
            patients.data.map(patient => patientMap.set(patient.id, patient));
            this.cache.set(domainId, patientMap);
            this.allInitialized.set(domainId, true);
        }

        return this.cache.get(domainId);
    }

    public async get(domainId: string, id: string, invalidateCache?: boolean) {
        if(invalidateCache)
            this.cache.get(domainId)?.delete(id);

        if(!this.cache.has(domainId))
            this.cache.set(domainId, new Map<string, Patient>())

        if(!this.cache.get(domainId)?.get(id)) {
            const patient = await this.api.get<Patient>(`domains/${domainId}/patients/${id}`);
            this.cache.get(domainId)?.set(id, patient.data);
        }

        return this.cache.get(domainId)?.get(id);
    }
}