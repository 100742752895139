import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import Table from 'react-bootstrap/Table';
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetObjectCommand,
    S3,
    _Object,
    CommonPrefix,

} from "@aws-sdk/client-s3";
import { CognitoUserSession } from "amazon-cognito-identity-js"
import prettyBytes from "pretty-bytes";
import { useInjection } from "inversify-react";
import { Credentials } from "../services/Credentials";
import { Button, Spinner, Modal, ProgressBar, Alert, ListGroup } from "react-bootstrap";
import { Domain } from "../models/Domain";
import { AWSClientProvider } from "../services/AWSClientProvider";
import { ExportData } from "../services/ExportData";
import { API } from "../services/HTTP";
import { ConfirmModal } from "./ConfirmModal";
import { UserService } from "../services/User";
import { ListFileAction } from "../pages/FilesPage";
import { relativeTimestamp } from "../services/Utils";

export function ListFolders({ domain, isMultipleSelected, isProcessedPage = false, action }: { domain: Domain, isMultipleSelected: boolean, isProcessedPage?: boolean, action?: ListFileAction }) {
    const [t] = useTranslation();

    const api = useInjection<API>("API");

    const user = useInjection<UserService>(UserService);
    const awsClientProvider = useInjection<AWSClientProvider>(AWSClientProvider);
    const exportData = useInjection<ExportData>(ExportData);
    const bucket = isProcessedPage ? domain.processedBucket : domain.bucket;

    const [prefix, setPrefix] = useState<string>(""); // Where are we? Note that the root starts without "/" TODO: initial path from url
    const [files, setFiles] = useState<_Object[]>([]); // What files are currently shown
    const [directories, setDirectories] = useState<CommonPrefix[]>([]); // What directories are currently shown
    const [processingFiles, setProcessingFiles] = useState<{ file_name: string, triggered_at: string }[]>([]); 
    const [loading, setLoading] = useState(false); 
    const [loadingAWSClient, setLoadingAWSClient] = useState(true); 

    const [downloading, setDownloading] = useState(false);
    const [archiving, setArchiving] = useState(false);
    const [parsing, setParsing] = useState(false);
    const [parsingFailed, setParsingFailed] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [showArchiveObjectModal, setShowArchiveObjectModal] = useState(false);
    const [showArchiveListModal, setShowArchiveListModal] = useState(false);
    const [showDownloadListModal, setShowDownloadListModal] = useState(false);
    const [object2Archive, setObject2Archive] = useState<string>();
    const [currentSelection, setCurrentSelection] = useState<string[]>([]);
    const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

    const [nextToken, setNextToken] = useState<string | undefined>(undefined); // Are there more files/directories that are not yet loaded? If so here we have the next token
    const [s3Client, setS3Client] = useState<S3 | null>(null); // The current s3 client to show files in the folder
    const [session, setSession] = useState<CognitoUserSession | null>(null); // The current user session.
    const [isAdmin, setIsAdmin] = useState(false);
    const credentials = useInjection(Credentials);

    // If the session is refreshed or we select another domain
    // We need to build the correspoding s3 client.
    useEffect(() => {
        setLoadingAWSClient(true);

        const f = async () => {
            const s3 = await awsClientProvider.getS3Client(domain);
            setS3Client(s3);
            setPrefix("");
        }

        f().finally(() => setLoadingAWSClient(false));
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [user, domain, credentials]);

    // If the path (prefix) changes or if we get a new s3 client
    // we want to load the current directory.
    const reload = useCallback(() => {
        
        const listObjects = async (domain: Domain) => {
            // We use either the given s3 client or the current global one.
            if (!s3Client) {
                return;
            }

            const s3Response = await s3Client!.listObjectsV2({ Bucket: bucket, Delimiter: '/', Prefix: prefix });
            if (s3Response) {
                setDirectories(sortFolders(s3Response.CommonPrefixes ?? []));
                setFiles(sortAndFilterFiles(s3Response.Contents ?? []));
                setNextToken(s3Response.NextContinuationToken);
            } else {
                console.error("Empty response from listObjectsV2");
                console.error(domain);
            }
            
            const ecsResponse = await api.get(`domains/${domain.Id}/processing-files`)
            if (ecsResponse) {
                setProcessingFiles(ecsResponse.data.files)
            } else {
                console.error("Empty response from listTasks");
                console.error(domain);
            }
        }

        setLoading(true);
        listObjects(domain).finally(() => setLoading(false));

    }, [prefix, s3Client, api, isProcessedPage, domain]);

    useEffect(() => {
        reload();
    }, [reload, domain, s3Client])

    const downloadFile = useCallback(async (key: string) => {
        let filename = key.replaceAll('/', '_');
        if (filename.endsWith('.dat'))
            filename = filename.slice(0, filename.length - 4) + '.csem_dat'
        const command = new GetObjectCommand({ Bucket: bucket, Key: key, ResponseContentDisposition: `attachment; filename=${filename}` });

        const url = await getSignedUrl(s3Client!, command, { expiresIn: 3 * 60 });
        window.open(url, '_blank', 'noreferrer');
    }, [domain, s3Client]);

    const downloadFolder = useCallback(async (prefix: string) => {
        if (!domain) {
            console.error("No domain selected.")
            return;
        }

        await exportData.downloadPerPrefix(domain, bucket, prefix, (p) => setDownloadProgress(p * 100))

    }, [domain, s3Client]);

    const onArchiveObject = (() => setShowArchiveObjectModal(true));

    const archiveObject = useCallback(async (path: string) => {
        if (!domain) {
            console.error("No domain selected.")
            return;
        }
        setArchiving(true);

        const encodedPath = encodeURIComponent(path);

        await api.get(`domains/${domain.Id}/archive-files?path=${encodedPath}`)
        setShowArchiveObjectModal(false);
        setArchiving(false);

    }, [domain, s3Client, prefix])
    
    
    const archiveObjects = useCallback(async (list: string[]) => {
        try {
            list.map(path => {
                archiveObject(path);
            });
        } catch (error) { 
            console.error("Archiving error:", error)
        }
        setCurrentSelection([]);
        reload();
        setShowArchiveListModal(false)
    }, [reload, archiveObject])

    const downloadObjects = useCallback(async (list: string[]) => {
        try {
            list.map(path => {
                if(path.endsWith("/")) {
                    downloadFolder(path)
                } else {
                    downloadFile(path)
                }
            })
        } catch (error) {
            console.error("Download error:", error)
        }
        setCurrentSelection([]);
        reload();
        setShowDownloadListModal(false);
    }, [reload, downloadFolder, downloadFile])

    useEffect(() => {
        if(action == "Download") {
            setSelectedObjects(currentSelection)
            setShowDownloadListModal(true)
        } else if (action == "Archive") {
            setSelectedObjects(currentSelection)
            setShowArchiveListModal(true)
        } else if (action == "SelectAll") {
            setCurrentSelection([...directories.map(prefix => prefix.Prefix!), ...files.map(file => file.Key!)])
        } else if (action == "UnselectAll") {
            setCurrentSelection([])
        } else if(!isMultipleSelected && action=="") {
            setCurrentSelection([])
        }
    }, [action, isMultipleSelected]);

    // Sorting files, newest first.
    function sortAndFilterFiles(files: _Object[]) {
        return files.sort((f1, f2) => {
            if (!f1.LastModified)
                return 1;
            if (!f2.LastModified)
                return -1;
            if (f1.LastModified < f2.LastModified)
                return -1;
            if (f1.LastModified > f2.LastModified)
                return 1;
            return 0;
        }).filter(f => f.Key?.split("/").pop() !== ""); // In the Web UI to create a folder without content, an empty file is created, we filter those out.
    }

    // Sorting files alphabetically
    function sortFolders(folders: CommonPrefix[]) {
        return folders.sort((d1, d2) => {
            return d1.Prefix?.localeCompare(d2.Prefix ?? "") ?? -1;
        });
    }

    // Load more functionality, if there are more than 1000 entries.
    async function loadMore() {
        if (!nextToken) {
            console.error("Tried to load more but we do not have a next token.");
            return;
        }

        const response = await s3Client?.listObjectsV2({ Bucket: bucket, Delimiter: '/', Prefix: prefix, ContinuationToken: nextToken });
        if (response) {
            setDirectories(directories.concat(response.CommonPrefixes ?? []));
            setFiles(files.concat(response.Contents ?? []));
            setNextToken(response.NextContinuationToken);
        }
    }

    async function cd(dir: string) {
        if (dir === prefix) {
            return;
        }

        setPrefix(dir);
        setFiles([]);
        setDirectories([]);
    }

    // calculating the breadcrumbs
    var cur_path = "";
    const breadcrumbs = prefix.split("/").map(item => {
        cur_path += item + "/";
        return {
            full_path: cur_path,
            item: item
        }
    })

    useEffect(() => {
        if (parsing || parsingFailed) {
            const timer = setTimeout(() => {
                setParsing(false);
                setParsingFailed(false);
            }, 2000); 

            return () => clearTimeout(timer);
        }
    }, [parsing, parsingFailed]);

    if (loadingAWSClient)
        return <Spinner></Spinner>

    return <>

        {isProcessedPage && processingFiles.length > 0 && prefix === "" && (<>
            <Alert  key={"parsingFilesTable"} variant="success">
            {t("The parsing of the following files was triggered successfully")}:
                <ul>
                    {processingFiles?.map( file => ( 
                        <li><b>{new Date(file.triggered_at).toLocaleString()}:</b> {file.file_name}</li>
                    ))}
                </ul>
            </Alert>
        </>)}
        
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#" onClick={(e) => { // TODO href to navigatable path
                    e.preventDefault();
                    cd("");
                }}>Home</a></li>
                {breadcrumbs.filter(breadcrumb => breadcrumb.item !== "").map((breadcrumb, i, ls) => (
                    <li key={breadcrumb.full_path} className={`breadcrumb-item ${i} ${ls.length} ${(i === ls.length - 1) && 'active'}`}><a href="#" onClick={(e) => {
                        e.preventDefault();
                        cd(breadcrumb.full_path);
                    }}>{breadcrumb.item}</a></li>
                ))}
            </ol>
        </nav>

        <Table striped bordered hover>
            <thead>
                <tr>
                    {isMultipleSelected ? <th>{t("")}</th> : <></>}
                    <th>{t("Name")}</th>
                    <th>{t("Last Modified")}</th>
                    <th>{t("Size")}</th>
                    <th>{t("Action")}</th>
                </tr>
            </thead>
            <tbody>
                {directories.map(prefix => (
                    <tr key={prefix.Prefix} >
                        {isMultipleSelected ? 
                            <td align="center" valign="middle">
                                <input
                                    type="checkbox"
                                    name="selectObject"
                                    key={prefix.Prefix}
                                    checked={currentSelection.includes(prefix.Prefix!)}
                                    onChange={() => {
                                        currentSelection.includes(prefix.Prefix!) ?
                                        setCurrentSelection(currentSelection.filter(path => path !== prefix.Prefix!)) : 
                                        setCurrentSelection([...currentSelection, prefix.Prefix!]);                               
                                    }}
                                />
                            </td>
                        : <></>}
                        <td style={{ cursor: "pointer" }} onClick={() => {
                            cd(prefix.Prefix!);
                        }}>
                            <span><i className="bi bi-folder2"></i> {prefix.Prefix?.split("/").at(-2)}/</span>
                        </td>
                        <td>

                        </td>
                        <td>

                        </td>
                        <td>
                            {prefix.Prefix ? (<>
                                <Button onClick={(e) => {
                                    e.preventDefault();
                                    setDownloading(true);
                                    setDownloadProgress(0);
                                    downloadFolder(prefix.Prefix!)
                                        .catch(error => console.error('Download error:', error))
                                        .finally(() => {
                                            setDownloading(false);
                                        });
                                }}
                                title={t("Download")}>
                                    <i className="bi bi-download"></i>
                                </Button>
                                {isAdmin && (
                                    <Button variant="danger"
                                        style={{ marginLeft: "5px" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setObject2Archive(prefix.Prefix!)
                                            onArchiveObject();
                                        }}
                                        title={t("Archive")}>
                                        <i className="bi bi-archive"></i>
                                    </Button>
                                )}
                            </>) : (<></>)}
                        </td>
                    </tr>
                ))}

                <Modal show={downloading} onHide={() => { }}>
                    <Modal.Header>
                        <Modal.Title>{t("Downloading folder from S3...")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ProgressBar animated now={downloadProgress} label={`${Math.round(downloadProgress)}%`} />
                    </Modal.Body>
                </Modal>

                {files.map(content => (
                    <tr key={content.Key}>
                        {isMultipleSelected ? 
                            <td align="center" valign="middle">
                                <input
                                    type="checkbox"
                                    name="selectObject"
                                    key={content.Key}
                                    checked={currentSelection.includes(content.Key!)}
                                    onChange={() => {
                                        currentSelection.includes(content.Key!) ?
                                        setCurrentSelection(currentSelection.filter(path => path !== content.Key!)) : 
                                        setCurrentSelection([...currentSelection, content.Key!]);
                                    }}
                                />
                            </td>
                        : <></>}
                        <td>
                            {content.Key?.split("/").pop()}
                        </td>
                        <td>
                            {content.LastModified?.toDateString() ?? "-"}
                        </td>
                        <td>
                            {content.Size ? prettyBytes(content.Size) : "-"}
                        </td>
                        <td>
                            <Button onClick={(e) => {
                                e.preventDefault();
                                downloadFile(content.Key!);
                            }}
                            title={t("Download")}>
                                <i className="bi bi-download"></i>
                            </Button>
                            {isAdmin && domain.settings.parsingEnabled && !isProcessedPage && (
                                <>
                                <Button
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        try {
                                            api.post(`domains/${domain.Id}/parse-file`, { key: content.Key! });
                                        } catch (error) {
                                            console.error("Error triggering the file parsing: ", error);
                                            setParsingFailed(true);
                                        } finally {
                                            setParsing(true);
                                        };
                                    }}
                                    title={t("Parse")}>
                                        <i className={parsingFailed ? "bi bi-x-circle" : parsing ? "bi bi-check2-circle" : "bi bi-database-gear"}></i>                                    
                                </Button>
                            </>
                            )}
                            {isAdmin && (<>
                                <Button variant="danger"
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setObject2Archive(content.Key!);
                                        onArchiveObject();
                                    }}
                                    title={t("Archive")}>
                                    <i className="bi bi-archive"></i>
                                </Button>
                            </>)}
                        </td>
                    </tr>
                ))}

                {loading && (
                    <tr>
                        <td>
                            <Spinner animation="border" />
                        </td>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            &nbsp;
                        </td>
                    </tr>
                )}

            </tbody>
        </Table>

        {nextToken && (
            <Button onClick={loadMore}>Load more..</Button>
        )}

        <ConfirmModal
            showModal={showArchiveObjectModal}
            setShowModal={setShowArchiveObjectModal}
            title="Sure Archive File"
            buttonText="Archive"
            activeButtonText="Archiving"
            action={archiveObjects}
            item={[object2Archive]}
        ></ConfirmModal>

        <ConfirmModal
            showModal={showArchiveListModal}
            setShowModal={setShowArchiveListModal}
            title={`Are you sure you want to archive ${selectedObjects.length} file(s)/folder(s)?`}
            buttonText="Archive"
            activeButtonText="Archiving"
            action={archiveObjects}
            item={selectedObjects}
            text={selectedObjects.join("\n\n")}
        ></ConfirmModal>

        <ConfirmModal
            showModal={showDownloadListModal}
            setShowModal={setShowDownloadListModal}
            title={`Are you sure you want to download ${selectedObjects.length} file(s)/folder(s)?`}
            buttonText="Download"
            activeButtonText="Downloading"
            action={downloadObjects}
            item={selectedObjects}
            text={selectedObjects.join("\n\n")}
        ></ConfirmModal>
    </>
}
