import { MouseEventHandler, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface SpinningButtonProps extends Omit<ButtonProps, "onClick"> {
    onClick: (e: any) => Promise<any>
};


export const SpinningButton: React.FC<PropsWithChildren<SpinningButtonProps>> = ({ onClick, children, ...props }) => {

    const [loading, setLoading] = useState(false);
    const click: MouseEventHandler<HTMLButtonElement> = useCallback(async (e) => {
        setLoading(true);
        await onClick(e).finally(() => setLoading(false));
    }, []);

    return <Button {...props} onClick={click} disabled={props.disabled || loading}>
        {loading ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        /> : children}
    </Button>
}