import { injectable } from "inversify";
import { DateTime, DurationLike } from "luxon";

@injectable()
export class DateTimeHelper {

    defaultRecent: DurationLike = { minutes: 5};

    public isRecent(date?: Date | DateTime | string | null, recent?: DurationLike): boolean {
        if (!date) {
            return false;
        }

        if (!recent) {
            recent = this.defaultRecent;
        }

        if (date instanceof Date) {
            date = DateTime.fromJSDate(date);
        }

        if (typeof date === "string") {
            date = DateTime.fromISO(date);
        }

        const now = DateTime.now();
        return now.minus(recent) < date;
    }
}