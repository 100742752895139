import { CognitoIdentityCredentialProvider, fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { Auth } from "aws-amplify";
import awsConfig from "../aws-config.json";
import { AwsCredentialIdentity } from "@smithy/types";
import { injectable } from 'inversify';

@injectable()
export class Credentials {

    private creds: Map<string, {expiration: number, credentialsProvider: CognitoIdentityCredentialProvider}> = new Map();

    /**
     * 
     * @param roleArn The role we want to assume, needs to be assumably via a web identity.
     * @returns A cached credentials provider per role arn.
     */
    async getProvider(roleArn?: string) : Promise<CognitoIdentityCredentialProvider> {
        const session = await Auth.currentSession();
        if (session == null) {
            throw new Error("We try to assume a role but do not have a session.");
        }

        if(!roleArn) {
            roleArn = awsConfig.aws_cognito_identity_pool_roles.authenticated_arn;
        }

        if(!roleArn) {
            throw new Error("No role to assume given and no preferred role could be identified.");
        }

        if (!this.creds.has(roleArn) ||  this.creds.get(roleArn)!.expiration <= new Date().getTime() / 1000) {
            const c = await this.getFreshProvider(roleArn);
            this.creds.set(roleArn, c);
        }

        return this.creds.get(roleArn)!.credentialsProvider;
    }

    async getFreshProvider(roleArn: string): Promise<{expiration: number, credentialsProvider: CognitoIdentityCredentialProvider}> {
        const session = await Auth.currentSession();
        if (session == null) {
            throw new Error("We try to assume a role but do not have a session.");
        }
        
        let idToken = session.getIdToken().getJwtToken();
        let COGNITO_ID = `cognito-idp.${awsConfig.aws_cognito_region}.amazonaws.com/${awsConfig.aws_user_pools_id}`; // 'COGNITO_ID' has the format 'cognito-idp.REGION.amazonaws.com/COGNITO_USER_POOL_ID'
        let loginData = {
        [COGNITO_ID]: idToken,
        };

        return {expiration: session.getIdToken().getExpiration(), 
            credentialsProvider: fromCognitoIdentityPool({
            clientConfig: { region: awsConfig.aws_cognito_region },
            identityPoolId: awsConfig.aws_cognito_identity_pool_id,
            customRoleArn: roleArn, 
            logins: loginData,
        })};
    }
    
    async get(roleArn?: string): Promise<AwsCredentialIdentity> {
        return (await this.getProvider(roleArn))();
    }

}