import { useCallback, useEffect, useState, useRef } from "react";
import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Settings } from "../models/Settings";
import _ from 'lodash';
import { Attribute } from "../models/Attribute";

export function SettingsPage() {
    const [t] = useTranslation();

    let { domain_id } = useParams();  
    const api = useInjection<API>("API");
    
    const [loading, setLoading] = useState<boolean>(false);
    const [settings, setSettings] = useState<Settings>(); // the active settings
    const [currentSettings, setCurrentSettings] = useState<Settings>(); // the settings that are being modified
    const [errorMessage, setErrorMessage] = useState<{ [key: string]: string }>({});

    const [attributes, setAttributes] = useState<Attribute[]>([{incoming_key: "", storage_key: "", type: ""}]);

    let inKeyInput = useRef<HTMLInputElement>(null);
    let storageKeyInput = useRef<HTMLInputElement>(null);
    let typeInput = useRef<HTMLSelectElement>(null);

    const regexpIncoming = new RegExp('^[a-zA-Z0-9_-]*$');
    const regexpStorage = new RegExp('^[a-z0-9_-]*$');

    const [changesMade, setChangesMade] = useState<boolean>(false);

    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;

            const response = await api.get<Settings>(`domains/${domain_id}/settings`);
            const fetchedSettings = response.data;
            
            // need to clone, otherwise they both point to the same object in memory
            const clonedSettings = _.cloneDeep(fetchedSettings) 
            
            setSettings(fetchedSettings);
            setCurrentSettings(clonedSettings);
            if (clonedSettings.attributes) {
                setAttributes(clonedSettings.attributes);
            }

        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    useEffect(() => {
        reload();
    }, [reload]);

    useEffect(() => {
        if (!_.isEqual(settings, currentSettings)) {
            setChangesMade(true);
        } else {
            setChangesMade(false)
        }
    }, [settings, currentSettings])

    const editSettings = useCallback(async (settings: Settings) => {
        await api.put(`domains/${domain_id}/settings`, settings)
    }, [domain_id, api])

    const changeAttribute = (index: number, value: string, key: keyof Attribute) => {
        const newAttributes = [...attributes];
        newAttributes[index][key] = value;
        validate(index);
        setAttributes(newAttributes);
        setCurrentSettings({
            ...currentSettings!,
            attributes: newAttributes
        });
    }

    const removeAttribute = (index: number) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes)
        setCurrentSettings({
            ...currentSettings!,
            attributes: newAttributes
        });
    }

    const validate = useCallback(async (index: number) => {
        const incoming = inKeyInput.current!.value;
        const storage = storageKeyInput.current!.value;

        const newErrorMessage: { [key: string]: string } = {};

        if (!regexpIncoming.test(incoming)) {
            newErrorMessage[`incoming-${index}`] = ("Attribute name Regex Error");
        }

        if (!regexpStorage.test(storage)) {
            newErrorMessage[`storage-${index}`] = ("Attribute storing name Regex Error");
        }

        setErrorMessage(newErrorMessage);

    }, [errorMessage, regexpIncoming, regexpStorage]);

    return <>
        <div className="header-container">
            <div>
            <h1 className="header-title">
                {t("Settings")}
            </h1>
            <small className="header-domain">
                {t("Domain")}: {domain_id}
            </small>
            </div>
        </div>

        <Form style={{padding: "10px"}} onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);

            if(currentSettings == null) {
                throw("Error updating the settings")
            }

            editSettings(currentSettings)
                .then(() => window.location.reload())
                .catch(error => console.error('Error while updating the settings:', error))
                .finally(() => setLoading(false))
        }}>
            <Form.Group className="mb-3" controlId="cofemoDomain">
                <Form.Label className="h4"><strong> {t("Is this domain part of COFEMO?")} </strong></Form.Label>
                {loading ? (<>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled/>
                    </div>
                </>) : (<>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="domainToggle"
                            checked={currentSettings?.isCofemoDomain}
                            onChange={() => {
                                setCurrentSettings({
                                    ...currentSettings!,
                                    isCofemoDomain: !currentSettings?.isCofemoDomain 
                                })
                            }}
                        />
                        <label className="form-check-label" htmlFor="domainToggle">
                            {currentSettings?.isCofemoDomain ? t("Yes") : t("No")}
                        </label>
                    </div>
                </>)}
            </Form.Group>

            <Form.Group className="mb-3" controlId="parsingEnabled">
                <Form.Label className="h4"><strong> {t("Enable parsing on incoming data")} </strong></Form.Label>
                {loading ? (<>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled/>
                    </div>
                </>) : (<>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="domainToggle"
                            checked={currentSettings?.parsingEnabled}
                            onChange={() => {
                                setCurrentSettings({
                                    ...currentSettings!,
                                    parsingEnabled: !currentSettings?.parsingEnabled 
                                })
                            }}
                        />
                        <label className="form-check-label" htmlFor="domainToggle">
                            {currentSettings?.parsingEnabled ? t("Yes") : t("No")}
                        </label>
                    </div>
                </>)}
            </Form.Group>

            <Form.Group className="mb-3" controlId="deviceAdditionOnPostEnabled">
                <Form.Label className="h4"><strong> {t("Enable seamless addition of device on post data")} </strong></Form.Label>
                {loading ? (<>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled/>
                    </div>
                </>) : (<>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="domainToggle"
                            checked={currentSettings?.deviceAdditionOnPostEnabled}
                            onChange={() => {
                                setCurrentSettings({
                                    ...currentSettings!,
                                    deviceAdditionOnPostEnabled: !currentSettings?.deviceAdditionOnPostEnabled 
                                })
                            }}
                        />
                        <label className="form-check-label" htmlFor="domainToggle">
                            {currentSettings?.deviceAdditionOnPostEnabled ? t("Yes") : t("No")}
                        </label>
                    </div>
                </>)}
            </Form.Group>
            <Form.Group className="mb-3" controlId="timestreamRequired">
                <Form.Label className="h4"><strong> {t("Enable Timestream Database for Time-Dependent Data Storage?")} </strong></Form.Label>
                {loading ? (<>
                    <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled/>
                    </div>
                </>) : (<>
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="timestreamToggle"
                            checked={currentSettings?.isTimestreamRequired}
                            onChange={() => {
                                setCurrentSettings({
                                    ...currentSettings!,
                                    isTimestreamRequired: !currentSettings?.isTimestreamRequired 
                                })
                            }}
                        />
                        <label className="form-check-label" htmlFor="timestreamToggle">
                            {currentSettings?.isTimestreamRequired ? t("Yes") : t("No")}
                        </label>
                    </div>
                </>)}
            </Form.Group>

            {currentSettings?.isTimestreamRequired ? (<>
            <Form.Group className="mb-3" controlId="domainfields">
                <Form.Label className="h4"><strong> {t("Domain fields")} </strong></Form.Label>
                {loading ? (
                    <Spinner animation="border" />
                ) : (
                    <div className="container-fluid">
                        {attributes.map((attribute, index) => (
                            <div className="row" style={{ marginTop: "10px"}}>
                                <Form.Group className="col-md-4">
                                    {index === 0 && <Form.Label>{t("Attribute Name")}</Form.Label>}
                                    <Form.Control
                                        type="text"
                                        placeholder="device_id"
                                        value={attribute.incoming_key}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            changeAttribute(index, e.target.value, "incoming_key");
                                        }}
                                        ref={inKeyInput}
                                    />
                                    {errorMessage[`incoming-${index}`] && <div className="text-danger">{t(errorMessage[`incoming-${index}`])}</div>}
                                </Form.Group>
                                <Form.Group className="col-md-5">
                                {index === 0 && <Form.Label>{t("Store in Timestream as")}</Form.Label>}
                                    <Form.Control
                                        type="text"
                                        placeholder="core_device_id"
                                        value={attribute.storage_key}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            changeAttribute(index, e.target.value, "storage_key");
                                        }}
                                        ref={storageKeyInput}
                                    />
                                    {errorMessage[`storage-${index}`] && <div className="text-danger">{t(errorMessage[`storage-${index}`])}</div>}
                                </Form.Group>
                                <Form.Group className="col-md-2">
                                {index === 0 && <Form.Label>{t("Type")}</Form.Label>}
                                    <Form.Select
                                        id="type"
                                        value={attribute.type}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            changeAttribute(index, e.target.value, "type");
                                        }}
                                        ref={typeInput}
                                    >
                                    <option disabled>{attribute.type}</option>
                                    <option value="VARCHAR">VARCHAR</option>
                                    <option value="BOOLEAN">BOOLEAN</option>
                                    <option value="DOUBLE">DOUBLE</option>
                                    <option value="BIGINT">BIGINT</option>
                                    <option value="TIMESTAMP">TIMESTAMP</option>
                                    <option value="MULTI">MULTI</option>
                                    </Form.Select>
                                </Form.Group>
                                <button 
                                    type="button"
                                    className="btn btn-outline-danger col-md-1" 
                                    style={{ marginTop: "auto" }}
                                    onClick={(e) =>{
                                        e.preventDefault();
                                        removeAttribute(index);}
                                    }>
                                        {t("Remove")}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </Form.Group>
                <Button 
                    type="button" 
                    variant="outline-secondary"
                    style={{margin: "10px"}}
                    onClick={(e) => { 
                        e.preventDefault(); 
                        setAttributes([...attributes ,{incoming_key: "", storage_key: "", type: ""}])}}>
                    + {t("Add an attribute")}
                </Button>
            </>) : (<></>)}
        
            <div className="mb-4 d-flex justify-content-end" style={{ marginTop: "auto" }}>
            <Button 
                disabled={loading || !changesMade || Object.keys(errorMessage).length > 0} 
                variant="primary" 
                type="submit">
                { !loading && (<>{t("Apply")}</>) || (<><Spinner animation="border" size="sm" /> {t("Updating the settings")}... </>)}
            </Button>
            </div>
        </Form>
    </>
}