import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { RefObject, useCallback, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

type NotAuthorizedException = {message: string};

export function NewPasswordForm(props: { user: CognitoUser, requiredAttributes: string[], onSuccessfulAuthentication: () => void, onSessionExpired: (e: NotAuthorizedException) => void }) {
    let passwordInput = useRef<HTMLInputElement>(null);
    let passwordRepeatInput = useRef<HTMLInputElement>(null);

    const [errorMessage, setErrorMessage] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);

    const changePassword = useCallback(async(e: React.SyntheticEvent) => {
        setErrorMessage("");
        setLoginLoading(true);
        e.preventDefault();
        if(passwordInput.current?.value !== passwordRepeatInput.current?.value) {
            passwordInput.current!.value = "";
            passwordRepeatInput.current!.value = "";
            setErrorMessage("The two passwords did not match.")
            return;
        }

        try {
            let attrs: any = {};
            props.requiredAttributes.map(attr => {
                attrs[attr] = (document.getElementById(attr) as HTMLInputElement).value;
            })

            let user = await Auth.completeNewPassword(
                props.user,
                passwordInput.current!.value,
                attrs
            );
            props.onSuccessfulAuthentication();
        } catch(e: any) {
            if (e.message === "Invalid session for the user, session is expired.") {
                props.onSessionExpired(e);
            }
            
            setErrorMessage(e.message);
        } finally {
            setLoginLoading(false);
        }
    }, []);

    return <>
    <h5>You need to set a new password.</h5>
        <Form onSubmit={changePassword}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>New password</Form.Label>
                <Form.Control disabled={loginLoading} type="password" ref={passwordInput} required/>

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
                <Form.Label>Repeat new password</Form.Label>
                <Form.Control disabled={loginLoading} type="password" ref={passwordRepeatInput} required/>
            </Form.Group>

        <h5>Please also fill the required attributes below</h5>

            {props.requiredAttributes.map(attr => {
                return (
                <Form.Group key={attr} className="mb-3">
                    <Form.Label>{attr}</Form.Label>
                    <Form.Control disabled={loginLoading} type="text" placeholder={"Enter " + attr} id={attr} required/>

                </Form.Group>
            )})}
            {errorMessage !== "" && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>)}


            <Button disabled={loginLoading} variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    </>
}