import { inject, injectable } from "inversify";
import { DomainRepository } from "./DomainRepository";

@injectable()
export class DefaultDomain {
    
    private localStorageDefaultDomainKey = "LOCAL_STORAGE_DEFAULT_DOMAIN_KEY";

    @inject(DomainRepository)
    public domainRepository!: DomainRepository;

    public set(domain_id?: string) {
        if(!domain_id)
            return localStorage.removeItem(this.localStorageDefaultDomainKey);
        localStorage.setItem(this.localStorageDefaultDomainKey, domain_id);
    }

    public async get(): Promise<string | null> {
        const domains = await this.domainRepository.all();
        const default_domain_id = localStorage.getItem(this.localStorageDefaultDomainKey);

        // If we have a default domain saved and it exists we return it.
        if(default_domain_id && domains.has(default_domain_id))
            return default_domain_id;

        // We do not seem to have a default domain yet, so make sure its cleared.
        localStorage.removeItem(this.localStorageDefaultDomainKey);

        // If we have domains we set the first one as default.
        if(domains.size > 0) {
            const [first_domain] = domains.values();
            this.set(first_domain.Id);
            return first_domain.Id;
        }

        // We have no domains so we cannot return anything.
        return null;
    }
}