import React from "react";

interface ErrorComponentProps {
    error: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
    return (
        <div id="error-page">    
            <i>{error}</i>
        </div>
    );
};

export default ErrorComponent;

