import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useState } from 'react';
import { registerLocale } from  "react-datepicker";
import { frCH, de, enGB } from 'date-fns/locale';
import { addDays, subDays, isToday } from 'date-fns';
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from 'react-bootstrap';

export function PickDate(props: { onDateSelect: (date: Date) => void, selectedDate: Date | null, startDate: Date}) {

    const [t, i18n] = useTranslation();

    registerLocale('en-GB', enGB);
    registerLocale('fr-CH', frCH);
    registerLocale('de', de);

    const [selectedDate, setSelectedDate] = useState<Date | null>(props.selectedDate);

    const handleDateChange = (date: Date | null) => {
        if (date) {
          setSelectedDate(date);
          props.onDateSelect(date); 
        }
      };

    const handlePrevDay = (date: Date | null) => {
      if (date) {
        const newDate = subDays(date, 1);
        setSelectedDate(newDate);
        props.onDateSelect(newDate); 
      }
    };

    const handleNextDay = (date: Date | null) => {
      if (date) {
        const newDate = addDays(date, 1);
        setSelectedDate(newDate);
        props.onDateSelect(newDate); 
      }
    };

    return <>
    <Row>
      <Col>
        <Button onClick={(e) => {e.preventDefault(); handlePrevDay(selectedDate)}} variant="btn btn-outline-secondary"> <i className="bi bi-chevron-left"></i> {t("Previous Day")}</Button>
      </Col>
      <Col className="d-flex justify-content-center">
        <DatePicker
            showIcon
            selected={selectedDate} 
            onChange={handleDateChange}
            locale={t("dateFormat")}
            dateFormat="yyyy/MM/dd"
            todayButton={t("Today")}
            toggleCalendarOnIconClick
            includeDateIntervals={[
              { start: subDays(props.startDate, 1) , end: new Date() },
            ]}
        ></DatePicker>
        </Col>
        <Col>
        <Button className="float-end" onClick={(e) => {e.preventDefault(); handleNextDay(selectedDate)}} disabled={isToday(selectedDate!)} variant="btn btn-outline-secondary">{t("Next Day")} <i className="bi bi-chevron-right"></i></Button>
        </Col>
    </Row>

    </>    
}
