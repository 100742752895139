import { Lambda } from "@aws-sdk/client-lambda";
import { Auth } from "aws-amplify";
import { useCallback, useRef, useState } from "react";
import { Button, Card, Form, ProgressBar, Spinner, Stack } from "react-bootstrap";
import awsConfig from '../aws-config.json'
import awsResources from '../aws-resources.json'
import { Credentials } from "../services/Credentials";
import { useInjection } from "inversify-react";
import background from "../assets/CSEM_background.jpg"
import { CognitoUser } from 'amazon-cognito-identity-js';
import { NewPasswordForm } from "./NewPasswordForm";
import { LoginRedirectStorage } from "../services/LoginRedirectStorage";

export function Login(props: { authLoading: boolean, onSuccessfulAuthentication: () => void }) {

    const credentials = useInjection(Credentials);
    const loginRedirectStorage = useInjection(LoginRedirectStorage);
    const emailInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);

    const [loginLoading, setLoginLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const [newPWRequiredAttributes, setNewPWRequiredAttributes] = useState([]);
    const [newPasswordUser, setNewPasswordUser] = useState<CognitoUser | any>(null);

    const attachIoTPolicy = async function () {
        const lambdaClient = new Lambda({
            region: awsConfig.aws_cognito_region,
            credentials: await credentials.getProvider()
        });

        return new TextDecoder().decode((await lambdaClient.invoke({
            FunctionName: awsResources.IoTAttachPrincipalLambdaName,

        })).Payload);
    };

    const cognitoLogin = useCallback(async (e: React.SyntheticEvent) =>  {
        e.preventDefault();
        loginRedirectStorage.storeRedirect();
        setLoginLoading(true);
        setErrorMessage("");
        let user = await Auth.signIn(emailInput.current?.value ?? '', passwordInput.current?.value).catch(e => {
            if (e.message !== "Incorrect username or password.") {
                throw e;
            }

            setErrorMessage(e.message);
            return false;
        }).finally(() => {
            setLoginLoading(false);
        });

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            setNewPasswordRequired(true);
            setNewPWRequiredAttributes(requiredAttributes);
            setNewPasswordUser(user);
        }

        if (user)
            props.onSuccessfulAuthentication();
    }, []);

    return <div className="bg-img" style={{ backgroundImage: `url(${background})` }}>
        {props.authLoading && (
            <Card className="login-modal">
                <Spinner color="black"></Spinner>
            </Card>
        ) || (
                <Card className="login-modal">
                        <Stack gap={3}>

                    {!newPasswordRequired && (<>
                        <h2>CSEM Employees </h2>
                        <Button variant="primary" onClick={async () => {
                            loginRedirectStorage.storeRedirect();
                            await Auth.federatedSignIn({ customProvider: 'CSEM-Azure-AD' });
                            await attachIoTPolicy();
                        }}>Login</Button>
                        <hr />
                        <h2>External </h2>
                        <Form onSubmit={cognitoLogin}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control disabled={loginLoading} type="email" placeholder="Enter email" ref={emailInput} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control disabled={loginLoading} type="password" placeholder="Password" ref={passwordInput} />
                            </Form.Group>
                            {errorMessage !== "" && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>)}
                            

                            <Button disabled={loginLoading} variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form></>
                    ) || (<NewPasswordForm user={newPasswordUser} requiredAttributes={newPWRequiredAttributes} onSuccessfulAuthentication={props.onSuccessfulAuthentication} onSessionExpired={e => {
                        setErrorMessage(e.message);
                        setNewPasswordUser(null);
                        setNewPWRequiredAttributes([]);
                        setNewPasswordRequired(false);
                    }}/>)}
                    </Stack>
                    
                </Card>
            )}


    </div>

}