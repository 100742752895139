import * as React from 'react';
import { Container } from 'inversify';
import { Provider } from 'inversify-react';
import { Credentials } from './Credentials';
import { createClient } from './MQTTClient';
import { mqtt5 } from 'aws-iot-device-sdk-v2';
import { API, PublicAPI, createClient as createHttpClient, createPublicClient as createPublicHttpClient } from './HTTP';
import { DateTimeHelper } from './DateTimeHelper';
import { UserService } from './User';
import { LoginRedirectStorage } from './LoginRedirectStorage';
import { DomainRepository } from './DomainRepository';
import { DefaultDomain } from './DefaultDomain';
import { PatientsRepository } from './PatientsRepository';
import { ExportData } from './ExportData';
import { AWSClientProvider } from './AWSClientProvider';
import { FetchTokenizedTimestreamData } from './FetchTokenizedTimestreamData';

export type MQTTClientProvider = (roleArn?: string) => Promise<mqtt5.Mqtt5Client>;

// in functional component
export const DependencyInjection: React.FC<{children: any}> = (props) => {
    return (
        <Provider container={() => {
            const container = new Container();

            container.bind<Credentials>(Credentials).toSelf();
            container.bind<DomainRepository>(DomainRepository).toSelf();
            container.bind<PatientsRepository>(PatientsRepository).toSelf();
            container.bind<DefaultDomain>(DefaultDomain).toSelf();
            container.bind<LoginRedirectStorage>(LoginRedirectStorage).toSelf();
            container.bind<DateTimeHelper>(DateTimeHelper).toSelf();
            container.bind<UserService>(UserService).toSelf();
            container.bind<API>("API").toDynamicValue(_ => createHttpClient());
            container.bind<PublicAPI>("PublicAPI").toDynamicValue(_ => createPublicHttpClient());
            container.bind<ExportData>(ExportData).toSelf();
            container.bind<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData).toSelf();
            container.bind<AWSClientProvider>(AWSClientProvider).toSelf();

            container.bind<MQTTClientProvider>("MQTTClientProvider").toFactory((context) => {
                return async (roleArn?: string) => await createClient(context, roleArn);
            })
            return container;
        }}>
            {props.children}
        </Provider>
    );
};