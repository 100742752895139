import React from 'react';
import {
  Chart as ChartJS,
  ChartOptions,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,

} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { useTranslation } from "react-i18next";
import {CofemoTimestreamResponse } from '../models/TimestreamResponse';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend
);

export function GraphDisplay({tempData, selectedDate}: { tempData: CofemoTimestreamResponse, selectedDate: Date }) {

  const [t, i18n] = useTranslation();

  const startOfDay = new Date(selectedDate);
  startOfDay.setHours(0,0,0,0);
  const endOfDay = new Date(selectedDate);
  endOfDay.setHours(23,59,59,0);

  const options: ChartOptions<"scatter"> = {
    responsive: true,
    layout: {
      padding: 5 // avoid graph scaling when empty vs with data
    },
    plugins: {
        legend: {
            display: true,
            position: "chartArea",
            title: {
              display: true,
              text: `${t("Measurement Quality")}:`,
              font: {
                weight: "bold"
              }
            },
            labels: {
              usePointStyle: true,
            },  
            fullSize: false,
        },
        title: {
            display: true,
            text: `${t('CORE Temperature Evolution over Time')} - ${startOfDay.toLocaleDateString(t("dateFormat"), { year: 'numeric', month: 'long', day: 'numeric' })}`, 
        },
        tooltip: {
          enabled: true,
          mode: 'nearest', 
          intersect: false,
          displayColors: false,
          callbacks: {
              title: function(tooltipItems) {
                return new Date(tooltipItems[0].parsed.x).toLocaleDateString(t("dateFormat"), { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
              },
              label: function(tooltipItem) {
                return `${t("Temperature")}: ${Math.round(tooltipItem.parsed.y * 10) / 10}°, ${t("Quality")}: ${tooltipItem.dataset.label}`;
            }
          }
        },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'HH:mm' // Use 24-hour format
        },
        },
        min: startOfDay!.toISOString(),
        max: endOfDay!.toISOString(),
      },
      y: {
        min: 35,
        max: 42,
        type: 'linear'
      }
    },
  };

  interface DataPoint {
    x: Date,
    y: number
  }

  interface QualityData {
    excellent: DataPoint[];
    good: DataPoint[];
    fair: DataPoint[];
    poor: DataPoint[];
  }

  const timestamps = tempData.timestamps.map(timestamp => new Date(timestamp));
  const datas: QualityData = timestamps.reduce((acc: QualityData, timestamp, i) => {
    if (tempData.core_measurement_quality[i] === "POOR") acc.poor.push({x: timestamp, y: tempData.core_temperature[i]})
    else if (tempData.core_measurement_quality[i] === "FAIR") acc.fair.push({x: timestamp, y: tempData.core_temperature[i]})
    else if (tempData.core_measurement_quality[i] === "GOOD") acc.good.push({x: timestamp, y: tempData.core_temperature[i]})
    else if (tempData.core_measurement_quality[i] === "EXCELLENT") acc.excellent.push({x: timestamp, y: tempData.core_temperature[i]})
    return acc;
  }, {excellent: [], good: [], fair: [], poor: []})

  // The following creates a step function for the alert temperature.
  const alert_temperatures: DataPoint[] = [];
  let last_alert_temperature: number | null = null;
  if (tempData.alert_temperature) {
      tempData.alert_temperature.map((temp, i) => {
        if (temp === null) {
          return;
        }

        // The first point we want to go from start of day
        if (last_alert_temperature === null) {
          alert_temperatures.push({x: startOfDay, y: temp})
          last_alert_temperature = temp
          return
        }

        // All consecutive points that change the alert temperature we make a step
        if (last_alert_temperature != temp) {
          alert_temperatures.push({x: timestamps[i], y: last_alert_temperature})
          alert_temperatures.push({x: timestamps[i] , y: temp})
          last_alert_temperature = temp
        }
      })

      // Lastly we extend the last point until the end of day.
      if (last_alert_temperature) {
        alert_temperatures.push({x: endOfDay, y: last_alert_temperature})
      }
  }  

  const displayData = {
    labels: timestamps,
    datasets: [
      {
        label: t('Excellent'),
        data: datas.excellent,
        borderColor: '#c0d1007f',
        backgroundColor: '#c0d1007f',
      },
      {
        label: t('Good'),
        data: datas.good,
        borderColor: '#ffcc3382',
        backgroundColor: '#ffcc3382',
      },
      {
        label: t('Fair'),
        data: datas.fair,
        borderColor: '#ff993381',
        backgroundColor: '#ff993381',
      },
      {
        label: t('Poor'),
        data: datas.poor,
        borderColor: '#a61e5b83',
        backgroundColor: '#a61e5b83',
      },
      {
        label: t('Alert temperature'),
        type: 'line',
        data: alert_temperatures,
        borderColor: '#f61e5b83',
        backgroundColor: '#f61e5b83',
      } as any, // Somehow because we add type: line, the type system is confused, so we need as any here.
    ]
  };

  ChartJS.register(PointElement, Tooltip, Legend, TimeScale); 

  return <>
      <Scatter options={options} data={displayData} />
  </>
}