import { CreatePatient } from "../models/Patient"
import { useCallback, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next";

export function NewPatientForm(props: { createPatient: (domain: CreatePatient) => Promise<void> }) {

    // Assuming the types for variables
    type StringRef = React.MutableRefObject<HTMLInputElement | null>;
    type NumberRef = React.MutableRefObject<HTMLInputElement | null>;
    type StringListRef = React.MutableRefObject<HTMLInputElement[]>;

    let IDInput: StringRef = useRef<HTMLInputElement>(null);
    let alertTemperatureInput: NumberRef = useRef<HTMLInputElement>(null);
    let alertPhoneInput: StringListRef = useRef<HTMLInputElement[]>([]);
    let languageInput = useRef<HTMLSelectElement | null>(null);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<{ [key: string]: string }>({});
    const [HTTPErrorMessage, setHTTPErrorMessage] = useState<any>(null);

    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>(['']);
    const [t, i18n] = useTranslation();

    // TODO: adjust according to specifications (+ the validation)
    const regexp = new RegExp('^[a-zA-Z0-9_-]*$');
    const regexpPhone = new RegExp('^(?:\\+41\\s*\\d{2}\\s*\\d{3}\\s*\\d{2}\\s*\\d{2})?$');
    const regexpTemp = new RegExp('^[0-9]+(?:\.[0-9]+)?$');

    const addPhoneNumber = () => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers.push('');
        setPhoneNumbers(newPhoneNumbers);
    };

    const changePhoneNumber = (index: number, value: string) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index] = value;
        setPhoneNumbers(newPhoneNumbers);
        validate(); // Ensure validate is called after updating the state
    };

    const removePhoneNumber = (index: number) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers.splice(index, 1); // Remove the phone number field at the specified index
        setPhoneNumbers(newPhoneNumbers);
    };

    const validate = useCallback(async () => {
        const ID = IDInput.current!.value;
        const alertTemperature = alertTemperatureInput.current!.value;
        const alertPhone = alertPhoneInput.current?.map(input => input.value) ?? [];

        const newErrorMessage: { [key: string]: string } = {};

        if (!regexp.test(ID)) {
            newErrorMessage['id'] = ("ID Regex Error");
        }

        if (!regexpTemp.test(alertTemperature) || +alertTemperature < 36 || +alertTemperature > 40) {
            newErrorMessage['temp'] = ("Temperature Regex Error");
        }

        alertPhone.forEach((phoneNumber, index) => {
            if (!regexpPhone.test(phoneNumber)) {
                newErrorMessage[`phone-${index}`] = ("Phone number Regex Error");
            }
        })

        setErrorMessage(newErrorMessage);

        if (Object.keys(newErrorMessage).length > 0) {
            setSubmitEnabled(false);
        }
        else setSubmitEnabled(true);

    }, [errorMessage]);

    return <>
        <h5>{t("New Patient")}</h5>
        <Form onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            props.createPatient({
                id: IDInput.current!.value,
                alert_temperature: parseFloat(alertTemperatureInput.current!.value),
                language: languageInput.current!.value,
                alert_phones: alertPhoneInput.current!.map(input => input.value),
                alert_enabled: true
            }).catch(err => {
                    setHTTPErrorMessage(err.response.status);
                    setSubmitEnabled(false);
                })
                .finally(() => setLoading(false));
        }}>
            <Form.Group className="mb-3" controlId="formID">
                <Form.Label>Patient ID*</Form.Label>
                <Form.Control onChange={validate} disabled={loading} type="text" ref={IDInput} required />
                {errorMessage[`id`] && <div className="text-danger">{t(errorMessage[`id`])}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTemperature">
                <Form.Label>{t("Alert Temperature")}*</Form.Label>
                <Form.Control onChange={validate} disabled={loading} type="text" ref={alertTemperatureInput} required />
                {errorMessage[`temp`] && <div className="text-danger">{t(errorMessage[`temp`])}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLanguage">
                <Form.Label>{t("Language")}*</Form.Label>
                <Form.Select disabled={loading} ref={languageInput}>
                    {Object.keys(i18n.store.data).map(lng => <option value={lng} key={lng} selected={i18n.language === lng}>{t(lng)}</option>)}
                </Form.Select>
            </Form.Group>

            {phoneNumbers.map((phoneNumber, index) => (
                <Form.Group className="mb-3" controlId={`formBasicPhoneNumber-${index}`} key={index}>
                    <Form.Label>{`${t("Alert Phone Number")} ${index + 1} ${index === 0 ? "*" : ""}`}</Form.Label>
                    <Form.Control
                        onChange={(e) => {
                            changePhoneNumber(index, e.target.value);
                            validate();
                        }}
                        ref={(input: HTMLInputElement | null) => {
                            // Assign the input ref to the corresponding index
                            if (input) {
                                alertPhoneInput.current[index] = input;
                            }
                        }}
                        disabled={loading}
                        type="text"
                        value={phoneNumber}
                        required
                    />
                    {index !== 0 && ( // Only show remove button for additional phone numbers
                        <Button variant="danger" onClick={() => removePhoneNumber(index)}>
                            {t("Remove")}
                        </Button>
                    )}
                    {errorMessage[`phone-${index}`] && <div className="text-danger">{t(errorMessage[`phone-${index}`])}</div>}
                </Form.Group>
            ))}
            <Button onClick={addPhoneNumber} variant="secondary" type="button">{t("Add Phone Number")}</Button>

            <div className="text-danger"> {HTTPErrorMessage === 409 ? `${t("Patient ID exists")}` : null} </div>
            <Button disabled={loading || !submitEnabled} variant="primary" type="submit">
                {(!loading) && (<>{t("Submit")}</>) || (<><Spinner animation="border" size="sm" /> {t("Creating the patient")}... </>)}
            </Button>
        </Form>

    </>
}