import { Auth } from "aws-amplify";
import { injectable } from "inversify";
import awsResources from '../aws-resources.json'

@injectable()
export class UserService {
    public async getSession() {
        return Auth.currentSession();
    }

    public async getUser() {
        return Auth.currentAuthenticatedUser();
    }

    public async getAttributes() {
        const user = this.getUser();
        return new Map((await Auth.userAttributes(user)).map(attr => [attr.getName(), attr.getValue()]));
    }

    public async getCognitoGroups(): Promise<string[]> {
        return (await this.getSession()).getIdToken().payload["cognito:groups"] ?? [];
    }

    public async isInGroup(group: string) {
        return (await this.getCognitoGroups()).includes(group);
    }

    public async isAdmin() {
        return this.isInGroup(awsResources.XDataAdminGroupName);
    }
}