// A custom hook that builds on useLocation to parse

import React from "react";
import { useLocation } from "react-router-dom";
import { TFunction } from "i18next";

// the query string for you.
export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function relativeTimestamp(timestamp: string, t: TFunction<"translation", undefined>) {

    const now = new Date();
    const measurementTimestamp = new Date(timestamp);

    const timeDiff = now.getTime() - measurementTimestamp.getTime();
    const minutes = Math.floor(timeDiff / (1000 * 60));
    const hours = Math.floor(minutes / 60);

    if (minutes < 1) {
        return t("Just now");
    } else if (minutes < 60) {
        return t('min ago', {minutes: minutes});
    } else if (hours < 24) {
        return t('hours ago', {hours: hours});
    } else {
        // If more than 3 hours ago, show the full date
        return measurementTimestamp.toLocaleString(t('dateFormat'));
    }
}

// Excel specific date formatting, for plotting and readability
export function toExcelDate(timestamp: Date) {
    return `${timestamp.getFullYear()}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getDate().toString().padStart(2, '0')} ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}:${timestamp.getSeconds().toString().padStart(2, '0')}`
}