import { Button } from "react-bootstrap";

export function PageSelector({ currentPageNumber, totalPages, isLastPageReached, setCurrentPageNumber: setCurrentPageNumber }: { currentPageNumber: number, totalPages: number, isLastPageReached: boolean, setCurrentPageNumber: (currentPageNumber: number) => void}) {

    const handlePrevious = () => {
        if (currentPageNumber > 1) {
            setCurrentPageNumber(currentPageNumber - 1);
        }
    };

    const handleNext = () => {
        if (currentPageNumber < totalPages) {
            setCurrentPageNumber(currentPageNumber + 1);
        }
    };

    const handlePageClick = (page: number) => {
        setCurrentPageNumber(page);
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPageNumber - 2 && i <= currentPageNumber + 2)) {
                pages.push(
                    <Button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        variant="default"
                    >
                        {currentPageNumber === i ? <strong>{i}</strong> : i}
                    </Button>
                );
            } else if (i === 2 && currentPageNumber > 4) {
                pages.push(<span key={i}>...</span>);
            } else if (i === totalPages - 1 && currentPageNumber < totalPages - 3) {
                pages.push(<span key={i}>...</span>);
            }
        }
        if (!isLastPageReached) {
            pages.push(<span key={-1}>... &emsp;</span>)
        }
        return pages;
    };


    return <>
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Button onClick={handlePrevious} disabled={currentPageNumber === 1} variant="default" active>
            <i className="bi bi-caret-left-fill"></i>
        </Button>
        {renderPageNumbers()}
        <Button onClick={handleNext} disabled={currentPageNumber === totalPages} variant="default" active>
            <i className="bi bi-caret-right-fill"></i>
        </Button>
    </div>
    </>
}