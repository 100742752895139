import { injectable } from "inversify";

@injectable()
export class LoginRedirectStorage {
    private readonly StorageKey = 'LoginRedirectStorage.subUrl';

    public storeRedirect(subUrl?: string) {
        if(!subUrl) {
            subUrl = window.location.href.split("://")[1].split("/").slice(1).join("/");
        }
        console.log(subUrl);
        localStorage.setItem(this.StorageKey, subUrl);
    }

    public retrieveAndDeleteRedirect(): string | null {
        const subUrl = localStorage.getItem(this.StorageKey);
        localStorage.removeItem(this.StorageKey);
        return subUrl;
    }
}