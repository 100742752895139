import { useCallback, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap"
import { Patient, CreatePatient, EditPatient } from "../models/Patient";
import { useTranslation } from "react-i18next";

export function EditPatientForm(props: {editPatient: (domain: EditPatient) => Promise<void>, pat: Patient}) {
    const [t, i18n] = useTranslation();

    // Assuming the types for variables
    type BooleanRef = React.MutableRefObject<HTMLInputElement | null>;
    type NumberRef = React.MutableRefObject<HTMLInputElement | null>;
    type StringListRef = React.MutableRefObject<HTMLInputElement[]>;

    let alertStatusInput: BooleanRef = useRef<HTMLInputElement>(null);
    let alertTemperatureInput: NumberRef = useRef<HTMLInputElement>(null);
    let alertPhoneInput: StringListRef = useRef<HTMLInputElement[]>([]);
    let languageInput = useRef<HTMLSelectElement | null>(null);

    // Local state to track changes
    const [alertStatus, setAlertStatus] = useState<boolean>(
        props.pat.alert_enabled ?? false
    );
    const [alertTemperature, setAlertTemperature] = useState<string>(
        props.pat.alert_temperature?.toString() ?? ""
    );

    const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
        props.pat.alert_phones ? [...props.pat.alert_phones] : ['']
    );

    const [language, setLanguage] = useState<string>(
        props.pat.language ?? 'en'
    );

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<{ [key: string]: string }>({});
    const [submitEnabled, setSubmitEnabled] = useState(true);

    const regexpPhone = new RegExp('^(?:\\+41\\s*\\d{2}\\s*\\d{3}\\s*\\d{2}\\s*\\d{2})?$');
    const regexpTemp = new RegExp('^\\d{0,2}(\\.\\d{1,2})?$');

    const addPhoneNumber = () => {
        setPhoneNumbers(prevNumbers => [...prevNumbers, '']);
    };

    const changePhoneNumber = (index: number, value: string) => {
        setPhoneNumbers(prevNumbers => {
            const updatedNumbers = [...prevNumbers];
            updatedNumbers[index] = value;
            return updatedNumbers;
        });
    };

    const removePhoneNumber = (index: number) => {
        setPhoneNumbers(prevNumbers => {
            const updatedNumbers = [...prevNumbers];
            updatedNumbers.splice(index, 1);
    
            // Update the ref array to remove the reference of the deleted input
            alertPhoneInput.current.splice(index, 1);
    
            return updatedNumbers;
        });
    };

    const validate = useCallback(async() => {
        const alertTemperature = alertTemperatureInput.current!.value;
        const alertPhone = alertPhoneInput.current?.map(input => input.value) ?? [];

        const newErrorMessage: { [key: string]: string } = {};

        if (!regexpTemp.test(alertTemperature) || 36 > Number(alertTemperature) || Number(alertTemperature) > 40) {
            newErrorMessage['temp'] = ("Temperature Regex Error");
        }

        alertPhone.forEach((phoneNumber, index) => {
            if (!regexpPhone.test(phoneNumber)) {
                newErrorMessage[`phone-${index}`] = ("Phone number Regex Error");
            }
        })

        setErrorMessage(newErrorMessage);

        if (Object.keys(newErrorMessage).length > 0) {
            setSubmitEnabled(false);
        }
        else setSubmitEnabled(true);

    }, [errorMessage]);

    return <>
        <Form onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);

            props.editPatient({
                alert_temperature: alertTemperatureInput ? parseFloat(alertTemperatureInput.current!.value): props.pat.alert_temperature, 
                language: languageInput.current?.value ?? 'en',
                alert_phones: alertPhoneInput ? alertPhoneInput.current!.map(input => input.value) : props.pat.alert_phones,
                alert_enabled: alertStatus
            })
                .catch(err => {
                    setErrorMessage(err.toString());
                    setSubmitEnabled(false);
                })
                .finally(() => setLoading(false));
        }}>

            <Form.Group className="mb-3" controlId="formMonitoring">
                <Form.Label>{t("Monitoring Status")}</Form.Label>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="alertToggle"
                        checked={alertStatus}
                        onChange={() => setAlertStatus(!alertStatus)}
                        disabled={loading}
                    />
                    <label className="form-check-label" htmlFor="alertToggle">
                        {alertStatus ? "On" : "Off"}
                    </label>
                </div>
            </Form.Group>

            
            <Form.Group className="mb-3" controlId="formTemperature">
                <Form.Label>{t("Alert Temperature")}</Form.Label>
                <Form.Control 
                    onChange={(e) => {
                        setAlertTemperature(e.target.value);
                        validate();
                    }}
                    disabled={loading} 
                    type="text" 
                    ref={alertTemperatureInput}
                    value={alertTemperature}/>
                {errorMessage[`temp`] && <div className="text-danger">{t(errorMessage[`temp`])}</div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLanguage">
                <Form.Label>{t("Language")}*</Form.Label>
                <Form.Select disabled={loading} ref={languageInput}>
                        {Object.keys(i18n.store.data).map(lng => <option value={lng} key={lng} selected={lng === language}>{t(lng)}</option>)}
                </Form.Select>
            </Form.Group>

            {phoneNumbers.map((phoneNumber, index) => (
                <Form.Group className="mb-3" controlId={`formBasicPhoneNumber-${index}`} key={index}>
                    <Form.Label>{`${t("Alert Phone Number")} ${index + 1}`}</Form.Label>
                    <Form.Control 
                        onChange={(e) => {
                            changePhoneNumber(index, e.target.value);
                            validate();
                        }}
                        ref={(input: HTMLInputElement | null) => {
                            // Assign the input ref to the corresponding index
                            if (input) {
                              alertPhoneInput.current[index] = input;
                            }
                        }}
                        disabled={loading} 
                        type="text" 
                        value={phoneNumber}
                        />
                    {index !== 0 && ( // Only show remove button for additional phone numbers
                        <Button variant="danger" onClick={() => removePhoneNumber(index)}>
                            {t("Remove")}
                        </Button>
                    )}
                    {errorMessage[`phone-${index}`] && <div className="text-danger">{t(errorMessage[`phone-${index}`])}</div>}
                </Form.Group>
            ))}
            <Button onClick={addPhoneNumber} variant="secondary" type="button">{t("Add Phone Number")}</Button>


            <Button disabled={loading || !submitEnabled} variant="primary" type="submit">
                { !loading && (<>{t("Submit")}</>) || (<><Spinner animation="border" size="sm" /> {t("Editing the patient")}... </>)}
            </Button>
        </Form>
        
    </>
}