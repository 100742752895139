import { IoT } from "@aws-sdk/client-iot";
import { mqtt5, iot } from "aws-iot-device-sdk-v2";
import awsConfig from '../aws-config.json';
import { Credentials } from "./Credentials";
import { interfaces } from "inversify";
import { Lambda } from "@aws-sdk/client-lambda";
import awsResources from '../aws-resources.json'

/**
 * AWSCognitoCredentialsProvider. The AWSCognitoCredentialsProvider implements AWS.CognitoIdentityCredentials.
 *
 */
class AWSCognitoCredentialsProvider {
    private readonly credentials: Credentials;
    private cachedCredentials?: any;
    private roleArn?: string
    constructor(credentials: Credentials, roleArn?: string, expire_interval_in_ms?: number) {
        this.credentials = credentials;
        this.roleArn = roleArn;
        setInterval(async () => {
            await this.refreshCredentials();
        }, expire_interval_in_ms ?? 3 * 60 * 1000);
    }

    getCredentials() {
        return {
            aws_access_id: this.cachedCredentials?.accessKeyId ?? "",
            aws_secret_key: this.cachedCredentials?.secretAccessKey ?? "",
            aws_sts_token: this.cachedCredentials?.sessionToken,
            aws_region: awsConfig.aws_cognito_region
        }
    }

    async refreshCredentials() {
        this.cachedCredentials = await this.credentials.get(this.roleArn);
    }
}

export async function createClient(context: interfaces.Context, roleArn?: string): Promise<mqtt5.Mqtt5Client> {
    

    return new Promise(async (resolve, reject) => {
        const credentials = context.container.get(Credentials);

        const lambdaClient = new Lambda({
            region: awsConfig.aws_cognito_region,
            credentials: await credentials.getProvider(roleArn)
        });
    
        await lambdaClient.invoke({
            FunctionName: awsResources.IoTAttachPrincipalLambdaName,
    
        });
    
        const iotClient = new IoT({
            region: awsConfig.aws_cognito_region,
            credentialDefaultProvider: (await credentials.getProvider(roleArn)) as any
        });
    
        const provider = new AWSCognitoCredentialsProvider(credentials, roleArn);
        /** Make sure the credential provider fetched before setup the connection */
        await provider.refreshCredentials();
    
        let wsConfig: iot.WebsocketSigv4Config = {
            credentialsProvider: provider,
            region: awsConfig.aws_cognito_region
        }
        const endpoint = await iotClient.describeEndpoint({endpointType: 'iot:Data-ATS'}, {});
        let builder: iot.AwsIotMqtt5ClientConfigBuilder = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
            endpoint.endpointAddress!,
            wsConfig,
        );
    
        let client: mqtt5.Mqtt5Client = new mqtt5.Mqtt5Client(builder.build());
        let log = console.log;
        
        client.on('error', (error) => {
            reject(error);
        });
    
    
        client.on('attemptingConnect', (eventData: mqtt5.AttemptingConnectEvent) => {
        });
    
        client.on('connectionSuccess', (eventData: mqtt5.ConnectionSuccessEvent) => {
            resolve(client);
        });
    
        client.on('connectionFailure', (eventData: mqtt5.ConnectionFailureEvent) => {
            reject(eventData.error);
        });
    
        client.on('disconnection', (eventData: mqtt5.DisconnectionEvent) => {
            log("Disconnection event: " + eventData.error.toString());
            if (eventData.disconnect !== undefined) {
                log('Disconnect packet: ' + JSON.stringify(eventData.disconnect));
            }
        });
    
        client.on('stopped', (eventData: mqtt5.StoppedEvent) => {
            log("Stopped event");
        });

        client.start();
    });
}