import { useCallback, useEffect, useRef, useState } from "react";
import { Domain } from "../models/Domain";
import { Device } from "../models/Device";
import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { Button, Container, Form, InputGroup, Spinner } from "react-bootstrap";
import { UserService } from "../services/User";
import { DeviceC } from "./DeviceC";
import { useParams } from "react-router-dom";
import { DomainRepository } from "../services/DomainRepository";
import { useTranslation } from "react-i18next";
import { Patient } from "../models/Patient";
import { DomainHeader } from "../components/DomainHeader";

export function DevicesPage(props: { }) {
    const api = useInjection<API>("API");
    const user = useInjection<UserService>(UserService);
    const { t, i18n } = useTranslation();

    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState(false);
    const [patients, setPatients] = useState<Patient[] | null>(null);
    const [deviceMessages, setDeviceMessages] = useState<Map<string, { type: string, message: string }>>(new Map());
    const [deleteInProgress, setDeleteInProgress] = useState<Map<string, boolean>>(new Map());
    const domainRepository = useInjection<DomainRepository>(DomainRepository);

    let { domain_id } = useParams();
    const [domain, setDomain] = useState<Domain | null>();

    const newDeviceNameRef = useRef<HTMLInputElement>(null);
    const [createNewInProgress, setCreateNewInProgress] = useState(false);
    const [createNewMessage, setCreateNewMessage] = useState<{ type: string, message: string } | null>(null);
    const [overrideRequest, setOverrideRequest] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const deleteDevice = useCallback(async (device: Device) => {
        // First we go in a loading state, the devices.slice() will retrigger the rendering of the devices to show delete in progress.
        setDeleteInProgress(deleteInProgress.set(device.Id, true));
        setDevices(devices.slice());
        var newDevices = devices.slice();

        // We do the delete and then make sure to also setDevices() to trigger a rerendering.
        api.delete(`devices/${device.Id}`)
            .then(() => newDevices = devices.filter(d => d.Id !== device.Id))
            .catch(e => setDeviceMessages(deviceMessages.set(device.Id, { type: 'danger', message: e.message })))
            .finally(() => { setDeleteInProgress(deleteInProgress.set(device.Id, false)); setDevices(newDevices); });
    }, [devices, api, deleteInProgress, deviceMessages]);

    const reload = useCallback(async () => {


        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;
            const response = await api.get<Device[]>(`domains/${domain_id}/devices`);
            setDevices(response.data);
            setDomain(await domainRepository.get(domain_id));

            const pResponse = await api.get<Patient[]>(`domains/${domain_id}/patients`);
            setPatients(pResponse.data)
        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    const createDevice = useCallback(async () => {
        setCreateNewInProgress(true);
        setCreateNewMessage(null);
        const domainId = domain_id;
        const deviceId = newDeviceNameRef.current?.value
        const override = overrideRequest ? true : undefined;

        api.post(`devices`, {
            name: deviceId,
            domain_id: domainId,
            override: override
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                reload();
                setCreateNewMessage(null);
                setOverrideRequest(false);
                newDeviceNameRef.current!.value = '';
            })
            .catch(e => {
                console.error(e.response);
                if (e.response.status === 409) {
                    setCreateNewMessage({ type: 'warning', message: `Device with name ${deviceId} is already attached to another domain, do you want to move the wearable to this domain ${domain?.name}?` });
                    setOverrideRequest(true);
                } else {
                    throw e;
                }
            })
            .catch(e => setCreateNewMessage({ type: 'danger', message: e.message }))
            .finally(() => setCreateNewInProgress(false));


    }, [domain_id, overrideRequest, reload]);

    const patientChanged = useCallback(async (dev: Device, patientId: string) => {
        const newDevices = devices.map(device => device.Id == dev.Id ? {...dev, patient_id: patientId} : device );
        await api.put(`devices/${dev.Id}`, {
            patient_id: patientId == "" ? null : patientId
        });
        setDevices(newDevices);
        reload();
    }, [api, devices]);

    useEffect(() => {
        reload();
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [domain_id]);

    if (!domain_id)
        return <></>;

    return <>
        <DomainHeader title={t("Devices")} domain={domain_id ?? ""}>
            <Button disabled={loading} onClick={(e) => {e.preventDefault(); reload()}} variant="outline-secondary">
                {loading && (
                    <Spinner 
                    as="span"
                    animation="border"
                    size="sm"
                    role="status" />
                ) || (
                    <i className="bi bi-arrow-clockwise"></i>
                )}
            </Button>
        </DomainHeader>
        
        <div className="row">
            {loading && devices.length == 0 ? (<>
                <Spinner animation="border" />
            </>) : (<>
                {devices.map(dev =>
                    <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12" key={dev.Id}>
                        <DeviceC 
                            canDelete={isAdmin} 
                            deleteInProgress={deleteInProgress.get(dev.Id) ?? false} 
                            device={dev} 
                            domain={domain!}
                            patients={patients}
                            message={deviceMessages.get(dev.Id)} 
                            onDelete={deleteDevice}
                            onPatientChanged={patientId => patientChanged(dev, patientId)} />
                    </div>
                )
                }</>)}

            {isAdmin && (
                <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12">
                    <div className={`card bg-white mb-3`}>
                    <div className="card-header" style={{ fontSize: '15px' }} >
                        <div>
                            {t("Add new wearable")}
                        </div>
                    </div>
                    <div className="card-body">
                    <InputGroup className="mb-3">
                        <Form.Control
                        placeholder={t("Device Name")}
                        aria-label={t("Device Name")}
                        aria-describedby="basic-addon2"
                        disabled={overrideRequest}
                        type="text"
                        ref={newDeviceNameRef}
                        />
                        {
                            !overrideRequest &&
                            <Button disabled={createNewInProgress} href="#" onClick={(e) => { e.preventDefault(); createDevice(); }}>{t("Add")}</Button> ||
                            <>
                                <Button disabled={createNewInProgress} className="btn-warning" href="#" onClick={(e) => { e.preventDefault(); createDevice(); }}>{t("Add")}</Button>
                                <Button disabled={createNewInProgress} className="btn-secondary" href="#" onClick={(e) => { e.preventDefault(); setOverrideRequest(false); newDeviceNameRef.current!.value = ''; setCreateNewMessage(null) }}>Dismiss</Button>
                            </>
                        }
                    </InputGroup>
                        {
                            createNewMessage &&
                            <div className={`alert alert-${createNewMessage?.type}`} role="alert">
                                {createNewMessage?.message}
                            </div>
                        }
                    </div>
                    </div>
                </div>
            )}
        </div>
    </>;
}