import { useState, useCallback, useEffect } from "react";
import { Patient } from "../models/Patient";
import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { useParams } from "react-router-dom";
import { Domain } from "../models/Domain";
import { DomainRepository } from "../services/DomainRepository";
import { UserService } from "../services/User";
import { useTranslation } from "react-i18next";
import { Column, ColumnWithKey, SortableTable } from "../components/SortableTable";
import { DomainHeader } from "../components/DomainHeader";

export function ArchivedPage() {

    const api = useInjection<API>("API");
    const user = useInjection<UserService>(UserService);
    const [t, i18n] = useTranslation();

    const [archivedPatients, setArchivedPatients] = useState<Patient[]>([]);
    const [domain, setDomain] = useState<Domain | null>();
    const domainRepository = useInjection<DomainRepository>(DomainRepository);
    const [isAdmin, setIsAdmin] = useState(false);
    const [sortBy, setSortBy] = useState<string>("created_at");
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const [loading, setLoading] = useState(false);

    let { domain_id } = useParams();

    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;
            const response = await api.get<Patient[]>(`domains/${domain_id}/archived`);
            setArchivedPatients(response.data);

            setDomain(await domainRepository.get(domain_id));
        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    useEffect(() => {
        reload();
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [domain_id]);

    const columns: Column<Patient>[] = [
        {name: "Patient ID", key: "id"},
        {name: "Date Archived", key: "created_at", format: (item: string) => new Date(item).toLocaleString(t('dateFormat'))}
    ];

    return <>
        <DomainHeader title={t("Archived Patients")} domain={domain_id ?? ""} />
        
        <SortableTable
            columns={columns}
            data={archivedPatients}
            loading={loading}
            setSort={setSortBy}
            setOrder={setSortOrder}
        />
    </>
}