import { Button, Col, Container, Form, Modal, Nav, Navbar, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import logo from "../assets/CSEM_logo1.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export function PublicFrame() {
    const [t, i18n] = useTranslation();
    const [showLanguagePicker, setShowLanguagePicker] = useState(false);

    return <>
        <Container fluid>
            <Row className="main-content-row">
                <Col xxl={3} xl={4} id="frame-container">
                    <Navbar expand="md">
                        <Nav className="sidebar flex-column">
                            <Navbar.Brand>

                                <Row>
                                    <img style={{ width: "80%", maxWidth: "300px", margin: "15px" }} src={logo} alt="CSEM Logo" />
                                </Row>
                            </Navbar.Brand>
                            <Nav.Item>
                                <Navbar.Text>
                                    <Button onClick={() => setShowLanguagePicker(!showLanguagePicker)}
                                        variant='outline-primary' className="btn-sm">
                                        <i className="bi bi-translate"></i>
                                    </Button>
                                    <Modal show={showLanguagePicker} onHide={() => setShowLanguagePicker(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t("Choose language")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Select size="sm" id="languageInput" onChange={(e) =>  {
                                                i18n.changeLanguage(e.target.value);
                                                localStorage.setItem("DEFAULT_LANGUAGE", e.target.value);
                                                setShowLanguagePicker(false);
                                            }}>
                                                {Object.keys(i18n.store.data).map(lng => <option value={lng} key={lng} selected={i18n.language === lng}>{t(lng)}</option>)}
                                            </Form.Select>
                                        </Modal.Body>
                                    </Modal>
                                </Navbar.Text>
                            </Nav.Item>
                        </Nav>

                    </Navbar>
                </Col>
                <Col xxl={9} xl={8} id="page-content-wrapper">
                    <Outlet />
                </Col>
            </Row>

        </Container>


    </>
}