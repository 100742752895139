import { inject, injectable } from "inversify";
import { Domain } from "../models/Domain";
import * as HTTP from "./HTTP";

@injectable()
export class DomainRepository {
    private cache = new Map<string, Domain>();
    private allInitialized = false;

    @inject("API")
    public api!: HTTP.API;

    public async all(invalidateCache?: boolean) {
        if(invalidateCache) {
            this.cache.clear();
            this.allInitialized = false;
        }

        if(!this.allInitialized) {
            const domains = await this.api.get<Domain[]>(`domains`);
            domains.data.map(domain => this.cache.set(domain.Id, domain));
            this.allInitialized = true;
        }

        return this.cache;
    }

    public async get(id: string, invalidateCache?: boolean) {
        if(invalidateCache)
            this.cache.delete(id);

        if(this.cache.has(id))
            return this.cache.get(id);

        const domain = await this.api.get<Domain>(`domains/${id}`);
        this.cache.set(id, domain.data);
        return domain.data;
    }
}