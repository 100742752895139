import { useInjection } from "inversify-react";
import { Device } from "../models/Device";
import { DateTimeHelper } from "../services/DateTimeHelper";
import { Button, Form, Row, Modal } from "react-bootstrap";
import { MQTTClientProvider } from "../services/DependencyInjection";
import { useCallback, useEffect, useState } from "react";
import { mqtt, mqtt5 } from "aws-iot-device-sdk-v2";
import { Domain } from "../models/Domain";
import { Patient } from "../models/Patient";
import { useTranslation } from "react-i18next";
import { API } from "../services/HTTP";
import { relativeTimestamp } from "../services/Utils";
import { ConfirmModal } from "../components/ConfirmModal";

export function DeviceC(props: { canDelete: boolean, domain: Domain, deleteInProgress: boolean, device: Device, message: { type: string, message: string } | undefined, onDelete: (device: Device) => any, patients: Patient[] | null, onPatientChanged: (patientId: string) => Promise<any> }) {
    const dth = useInjection<DateTimeHelper>(DateTimeHelper);
    let dev = props.device;
    const mqttClientProvider = useInjection<MQTTClientProvider>("MQTTClientProvider");
    const [mqttClient, setMqttClient] = useState<mqtt5.Mqtt5Client | null>(null);
    const [progress, setProgress] = useState<{ gateway: string, file_name: string, uploaded_percentage: number } | null>(null);
    const [usbDownloadProgress, setUsbDownloadProgress] = useState<number | null>(null);
    const [localMessages, setLocalMessages] = useState<{ type: string, message: string }[]>([]);
    const [t, i18n] = useTranslation();
    const api = useInjection<API>("API");
    const [patientChangeError, setPatientChangeError] = useState<string | null>();
    const [patientChangeInProgress, setPatientChangeInProgress] = useState(false);
    const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);

    const progressTopic = `xem/${props.domain?.prefix}/+/${props.device.Id}/ch.csem.gg.file-uploader/progress`;
    const finishedTopic = `xem/${props.domain?.prefix}/+/${props.device.Id}/ch.csem.gg.file-uploader/upload-finished`;
    const usbDownloadProgressTopic = `xem/${props.domain?.prefix}/+/${props.device.Id}/usb/download/progress`;

    const addMessage = ({ type, message }: { type: string, message: string }) => {
        const messages = localMessages.slice();
        messages.push({
            type,
            message
        });
        setLocalMessages(messages);
    };

    const initClient = useCallback(async () => {
        if (!props.domain)
            return;

        let client: mqtt5.Mqtt5Client = await mqttClientProvider(props.domain.role);
        setMqttClient(client);

        await client.subscribe({
            subscriptions: [
                { qos: mqtt5.QoS.AtMostOnce, topicFilter: progressTopic },
                { qos: mqtt5.QoS.AtMostOnce, topicFilter: finishedTopic },
                { qos: mqtt5.QoS.AtMostOnce, topicFilter: usbDownloadProgressTopic }
            ]
        });

        // Make sure we unsubscribe again.
        return async () => {
            await client.unsubscribe({
                topicFilters: [progressTopic, finishedTopic, usbDownloadProgressTopic]
            });
        }
    }, [props.device, props.domain]);

    useEffect(() => {

        if (!mqttClient)
            return;

        let decoder = new TextDecoder("utf-8");
        mqttClient.on("messageReceived", (eventData: mqtt5.MessageReceivedEvent): void => {
            if (eventData.message.payload && eventData.message.topicName.endsWith('/ch.csem.gg.file-uploader/progress')) {
                const current = JSON.parse(decoder.decode(eventData.message.payload as ArrayBuffer));
                if (!progress || progress?.uploaded_percentage < current.uploaded_percentage || progress.file_name != current.file_name)
                    setProgress(current);
            }

            if (eventData.message.payload && eventData.message.topicName.endsWith('/ch.csem.gg.file-uploader/upload-finished')) {
                const current = JSON.parse(decoder.decode(eventData.message.payload as ArrayBuffer));
                addMessage({
                    type: 'success',
                    message: `File ${current.file_name} has been uploaded successfully.`
                });
            }
            if (eventData.message.payload && eventData.message.topicName.endsWith('/usb/download/progress')) {
                const stringNumber = decoder.decode(eventData.message.payload as ArrayBuffer);
                setUsbDownloadProgress(parseInt(stringNumber));
            }
        });

        return () => {
            mqttClient.removeAllListeners();
        }
    }, [mqttClient, addMessage]);

    // Remark: MQTT is currently turned off, as it was eating too many resources / money.
    // useEffect(() => {
    //     const s = initClient().catch(err => console.error(err));

    //     // Make sure we unsubscribe again.
    //     return () => {
    //         s.then(async (e) => e instanceof Function ? await e() : "");
    //     }
    // }, [initClient]);

    const onDeleteDevice = (() => setShowDeleteDeviceModal(true));

    return <div className={`card bg-white mb-3`}>
        <div className="card-header d-flex justify-content-between" style={{ fontSize: '15px' }} >
            <div>
                {dev.name} {dth.isRecent(dev.last_seen) && (<span className="badge bg-success">online</span>)}
            </div>
            <div>
                {props.canDelete && (
                    <Button className="btn-sm" href="#" variant="outline-danger" disabled={props.deleteInProgress} onClick={(e) => { e.preventDefault(); onDeleteDevice() }}><i className="bi bi-trash3"></i></Button>
                )}
            </div>
        </div>
        <div className="card-body">
            <h6 className="card-text">
            {t("Created at")}: {dev.created_at ? relativeTimestamp(dev.created_at, t) : t('Unknown')}
            </h6>
            <h6 className="card-text">
            {t("Last seen")}: {dev.last_seen ? relativeTimestamp(dev.last_seen, t) : t('Never')}
            </h6>
            {props.domain?.settings.isCofemoDomain && (<>
                <div className="card-text">
                    <Row>
                        <Form>
                            {t("Patient")}: <Form.Select disabled={!props.patients || patientChangeInProgress} value={dev.patient_id ?? ""} size="sm" id="languageInput" onChange={async (e) => {
                                try {
                                    setPatientChangeInProgress(true);
                                    await props.onPatientChanged(e.target.value);
                                    setPatientChangeError(null);
                                } catch (e: any) {
                                    console.error(e);
                                    setPatientChangeError("Could not change associated patient.")
                                } finally {
                                    setPatientChangeInProgress(false);
                                }
                            }}>
                                <option value="" key="">{t("No patient associated")} </option>
                                {(props.patients ?? []).map(patient => <option value={patient.id} key={patient.id}>{patient.id}</option>)}
                            </Form.Select>
                            {
                                patientChangeError &&
                                <div className={`alert alert-danger`} role="alert">
                                    {t(patientChangeError)}
                                </div>
                            }
                        </Form>
                    </Row>
                </div>
            </>) || (<></>)}

            {
                props.message &&
                <div className={`alert alert-${props.message.type}`} role="alert">
                    {props.message.message}
                </div>
            }

            {
                progress &&
                <p>
                    Uploading {progress.file_name} via {progress.gateway}: {Math.round(progress.uploaded_percentage)}%
                    <div className="progress">
                        <div className={`progress-bar w-${Math.round(progress.uploaded_percentage)}`} role="progressbar" style={{ width: `${progress.uploaded_percentage.toString()}%` }} aria-valuenow={Math.round(progress.uploaded_percentage)} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                </p>
            }

            {
                usbDownloadProgress &&
                <p>
                    Downloading data over usb: {Math.round(usbDownloadProgress)}%
                    <div className="progress">
                        <div className={`progress-bar w-${Math.round(usbDownloadProgress)}`} role="progressbar" style={{ width: `${usbDownloadProgress.toString()}%` }} aria-valuenow={Math.round(usbDownloadProgress)} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                </p>
            }

            {
                localMessages.map((m, i) => <div key={i} className={`alert alert-${m.type}`} role="alert">
                    {m.message}
                </div>)
            }
        </div>
        
        <ConfirmModal
            showModal={showDeleteDeviceModal}
            setShowModal={setShowDeleteDeviceModal}
            title="Sure Delete Device"
            buttonText="Archive"
            activeButtonText="Archiving"
            action={props.onDelete}
            item={dev}
        ></ConfirmModal>
    </div>
}