import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { UserService } from "../services/User";
import { useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Patient } from "../models/Patient";
import { Alert, Button, Spinner } from "react-bootstrap";
import { PatientsRepository } from "../services/PatientsRepository";
import { CreateOnboarding } from "../models/CreateOnboarding";
import { CreateMitigationActionCommand } from "@aws-sdk/client-iot";
import { Device } from "../models/Device";


export function CreateOnboardingPage() {
    const api = useInjection<API>("API");
    const user = useInjection<UserService>(UserService);
    const patientsRepository = useInjection<PatientsRepository>(PatientsRepository);

    let { domain_id } = useParams();
    const [params, setParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<String | null>();
    const [patients, setPatients] = useState<Map<string, Patient> | null>();
    const [success, setSuccess] = useState<boolean>(false);
    const [patientId, setPatientId] = useState<string | null>()
    const [saved, setSaved] = useState<CreateOnboarding | null>()
    const [coreAlreadyUsedBy, setCoreAlreadyUsedBy] = useState<string | null>(null)

    const saveDevice = useCallback(async (onboarding: CreateOnboarding) => {
        setSaving(true);
        try {
            await api.post(`domains/${domain_id}/onboarding-tickets`, onboarding);
            setSuccess(true);
            setSaved(onboarding);
        } catch (e) {
            setError((e as Error).message);
        } finally {
            setSaving(false);
        }
    }, [params, domain_id]);

    useEffect(() => {
        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;
            const patients = await patientsRepository.all(domain_id)

            // Check if the core device is already in use.
            try {
                const core = await api.get<Device>(`devices/CORE_${params.get('core_device')}`)
                setCoreAlreadyUsedBy(core.data.patient_id ?? null)
            } catch {
                setCoreAlreadyUsedBy(null);
            }
            setPatients(patients)
        };
        f().finally(() => setLoading(false));
    }, [domain_id]);

    if (success && saved)
        return <>
            <h1>OnBoarding on {domain_id}</h1>
            <p>Device name: {saved.name}</p>
            <p>Token: {saved.token}</p>
            <p>Core Device: {saved.device_id}</p>
            <p>Patient ID: {saved.patient_id}</p>
            <Alert key="success" variant="success">
                Device onboarding onboarding ticket has been created successfully, you can close the browser.
            </Alert>
            <Button onClick={() => window.open('','_self')?.close()}>Close</Button>
        </>

    if (coreAlreadyUsedBy)
        return <Alert key="danger" variant="danger">
                Cannot onboard with CORE device {params.get('core_device')} as this CORE device is already used by {coreAlreadyUsedBy}.
            </Alert>

    return <>
        <h1>OnBoarding on {domain_id}</h1>
        <p>Device name: {params.get('name') ?? 'UNKNOWN'}</p>
        <p>Token: {params.get('token') ?? 'UNKNOWN'}</p>
        <p>Core Device: {params.get('core_device') ?? 'UNKNOWN'}</p>
        {patients && (
            <select disabled={saving} id="patientInput" className="form-select" onChange={(e) => setPatientId(e.target.value)}>
                <option value="">Select a patient to associate the device to...</option>
                {Array.from(patients!.entries()).map(([patientId, patient]) => {
                    const in_use = (patient.associated_devices_ids ?? []).filter(associated_device_id => associated_device_id.startsWith("CORE_")).length > 0;
                    return <option disabled={in_use} value={patientId} key={patientId}>{patientId} {in_use && ("(in use)")}</option>;
                })}
            </select>
        ) || (
                <select id="patientInput" className="form-select" disabled>
                    <option value="">Loading...</option>
                </select>
            )}
        {error && (
            <Alert key="danger" variant="danger">
                {error}
            </Alert>
        )}
        <Button disabled={saving || patientId == null || patientId == ""} onClick={() => {
            if(!params.get('token')) {
                setError("No token given.");
                return
            }

            if(!params.get('name')) {
                setError("No name given.");
                return
            }

            if(!patientId) {
                setError("No patient selected.");
                return
            }
            if(!params.get('core_device')) {
                setError("No core device given.");
                return
            }

            saveDevice({
                token: params.get('token')!,
                name: params.get('name')!,
                patient_id: patientId!,
                device_id: `CORE_${params.get('core_device')!}`,
            })
         }}>{!saving && "Onboard Device" || "Saving..."}</Button>
    </>

}