import { Button, Form, Spinner } from "react-bootstrap"
import {CreateDomain} from "../models/CreateDomain"
import { useCallback, useRef, useState } from "react"

export function NewDomainForm(props: {createDomain: (domain: CreateDomain) => Promise<void>}) {

    let nameInput = useRef<HTMLInputElement>(null);
    let prefixInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const regexpName = new RegExp('^[a-zA-Z0-9_-]*$');
    const regexpPrefix = new RegExp('^[a-z0-9]*$');

    const validate = useCallback(async() => {
        const name = nameInput.current!.value;
        const prefix = prefixInput.current!.value;

        var errs: string[] = [];
        if (!regexpName.test(name)) {
            errs.push("Name must be letters, digits, underscore and dashes.");
        }

        if (!regexpPrefix.test(prefix)) {
            errs.push("Prefix must be lower case letters and numbers.");
        }

        if (errs.length > 0) {
            setErrorMessage(errs.join("\n"));
            setSubmitEnabled(false);
        } else {
            setErrorMessage("");
            setSubmitEnabled(true);
        }
    }, []);

    return <>
        <h5>New Domain</h5>
        <Form onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            props.createDomain({name: nameInput.current!.value, prefix: prefixInput.current!.value})
                .catch(err => {
                    setErrorMessage(err.toString());
                    setSubmitEnabled(false);
                })
                .finally(() => setLoading(false));
        }}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={validate} disabled={loading} type="text" ref={nameInput} required/>

            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPasswordRepeat">
                <Form.Label>Prefix</Form.Label>
                <Form.Control onChange={validate} disabled={loading} type="text" ref={prefixInput} required/>
            </Form.Group>

        
            {errorMessage != null && errorMessage !== "" && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>)}


            <Button disabled={loading || !submitEnabled} variant="primary" type="submit">
                { !loading && (<>Submit</>) || (<><Spinner animation="border" size="sm" /> Creating the domain, this might take a while... </>)}
            </Button>
        </Form>
    </>
}